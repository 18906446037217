import { mergeStyles, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import BookMarkPaneComponent from '../../bookMarkPane/bookMarkPane';
import CollapseBookMarkPane from '../../collapseBookMarkPane/bookMarkPane';
import ProgressBar from '../../progressBar/progressBar';
import TitlePane from '../../titlePane/titlePane';
import { BookMarkItem } from '../../../types/template';
import { PMOL_TYPE } from '../../../types/projectMolecule';

const contentStyle = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
  selectors: {
    '@media(max-width: 1100px)': {
      width: '97.4%',
      height: 'calc(100vh)',
      position: 'absolute',
    },
  },
});

const listStyle = mergeStyles({
  flex: '1 1 auto',
  width: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',
  marginLeft: '0',
});

const PMOLBookMarkPane = require('../../../components/projectMolecule/bookMarkPane/container').default;

const DocumentPaneSectionVp = (props: {
  collapseBookmarkPane: boolean;
  showProgress: boolean;
  handleScroll: () => void;
  isEdit: boolean;
  bookmarkList: BookMarkItem[];
  currentActiveSection: string;
  children: any;
  renderCommandBar: any;
  formData: any;
  type?: number;
  isPMOL?: boolean;
}) => {
  const [bookmarkDivClass, setBookmarkDivClass] = useState(
    'bookmark-panel-vp parent',
  );

  const _toggleBookmark = (): void => {
    setBookmarkDivClass('bookmark-panel-vp parent panel-on');
  };

  const _toggleBookmarkOut = (): void => {
    setBookmarkDivClass('bookmark-panel-vp parent');
  };


  return (
    <div style={{ width: '100%' }}>
      <Stack
        className="project-list-header right-panel"
        style={{ backgroundColor: 'rgb(255, 255, 255)', paddingRight: 0 }}
        styles={{ root: { width: '100%', paddingRight: 0 } }}
      >
        <TitlePane
          close={true}
          headerTitle={
            props.formData ? props.formData.title : ''
          }
          collapse={true}
          isCollapse={props.collapseBookmarkPane}
          type={props.type}
        />
        <ProgressBar show={props.showProgress}/>
        {props.renderCommandBar()}

      </Stack>

      <Stack
        className={`project-detail-list ${
          props.collapseBookmarkPane && screen.width > 1440
            ? 'vp-full-inner-container'
            : 'vp-inner-container'
        } cpc  card-container`}
        styles={{
          root: {
            width: '100%',
          },
        }}
      >
        { /* doc*/}
        <Stack
          className={contentStyle}
          horizontalAlign="start"
          verticalAlign="start"
          padding={0}
          gap={0}
          styles={{
            root: {
              width: '100%',
              height: '100%',
            },
          }}
        >
          <Stack
            className={listStyle}
            styles={{
              root: {
                width: '100%',
              },
            }}
            verticalFill
            gap={50}
            horizontalAlign="start"
            verticalAlign="start"
            onScroll={props.handleScroll}
          >
            {props.children}
          </Stack>
          <BookMarkPaneComponent
                isEdit={props.isEdit}
                divClass={bookmarkDivClass}
                currentActiveSection={props.currentActiveSection}
                list={props.bookmarkList}
                toggleBookmark={_toggleBookmark}
                toggleBookmarkOut={_toggleBookmarkOut}
              />
          {/* {props.collapseBookmarkPane && screen.width > 1440 ? (
            <CollapseBookMarkPane
              isEdit={props.isEdit}
              divClass={bookmarkDivClass}
              currentActiveSection={props.currentActiveSection}
              list={props.bookmarkList}
              toggleBookmark={_toggleBookmark}
              toggleBookmarkOut={_toggleBookmarkOut}
              pmolType={PMOL_TYPE.REGULAR}
              isVp={true}
            />
          ) : (
            !props.isPMOL ?
              <BookMarkPaneComponent
                isEdit={props.isEdit}
                divClass={bookmarkDivClass}
                currentActiveSection={props.currentActiveSection}
                list={props.bookmarkList}
                toggleBookmark={_toggleBookmark}
                toggleBookmarkOut={_toggleBookmarkOut}
              />
              :
              <PMOLBookMarkPane
                isEdit={props.isEdit}
                divClass={bookmarkDivClass}
                currentActiveSection={props.currentActiveSection}
                list={props.bookmarkList}
                toggleBookmark={_toggleBookmark}
                toggleBookmarkOut={_toggleBookmarkOut}
              />
          )} */}
        </Stack>
      </Stack>
    </div>
  );
};

export default DocumentPaneSectionVp;

import React, {useEffect, useState, MouseEvent as ReactMouseEvent} from 'react';
import {
    ContextualMenu,
    DetailsListLayoutMode,
    DirectionalHint,
    FontIcon,
    ICalloutProps,
    IColumn,
    IStackStyles,
    IStackTokens,
    ITooltipHostStyles,
    Icon,
    ScrollablePane,
    ScrollbarVisibility,
    SelectionMode,
    ShimmeredDetailsList,
    Stack,
    Sticky,
    StickyPositionType,
    Text,
    TooltipDelay,
    TooltipHost,
} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import {mergeStyles, mergeStyleSets} from '@fluentui/react/lib/Styling';
import {getProject, getTimeZone} from '../../../shared/util';
import {Selection} from '@fluentui/react/lib/DetailsList';
import '../../../shared/kanban/kanban.css';
import moment from 'moment';
import DragBox from '../../../shared/dnd/dragBox';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {filterMyDayPlanningResourceListPaneData, saveMyDayPlanningStateAttr} from '../../../reducers/myDayPlanningReducer';
import {AcceptType, GridView} from '../../../types/myDayPlanning';
import _ from 'lodash';
import usePrevious from '../../../hooks/usePrevious';
import {messageService} from '../../../services/messageService';
import {saveDayPlanningStateAttr} from "../../../reducers/dayPlanningReducer";
import { iconColors } from '../../../../theme';
import i18n from '../../../../i18n';
import {v4 as uuidv4} from 'uuid';
import { createAbsence, getAbsenceLeaveTypeDropdown } from '../../../reducers/humanResourceReducer';
import AbsenceModal from '../../humanResource/documentPane/absenceModal/component';
import { AbsenceModalDTO } from '../../../types/humanResource';

const iconClass = mergeStyles({
    fontSize: 14,
    height: 14,
    width: 14,
    margin: '0 5px',
});

export const classNames = mergeStyleSets({
    wrapper: {
        height: 'calc(100vh - 25vh)!important',
        position: 'relative',
    },
    filter: {
        paddingBottom: 20,
        maxWidth: 100,
    },
    header: {
        margin: 0,
    },
    row: {
        display: 'inline-block',
    },
    deepSkyBlue: [{color: 'deepskyblue'}, iconClass],
    red: [{color: 'red'}, iconClass],
    green: [{color: 'green'}, iconClass],
    orange: [{color: 'orange'}, iconClass],
    grey:[{color:'grey'}, iconClass]
});

const wrapStackTokens: IStackTokens = {childrenGap: 2};

const hostStyles: Partial<ITooltipHostStyles> = {root: {display: 'inline-block'}};

const calloutProps: Partial<ICalloutProps> = {
    styles: {
      root: {
        maxWidth: 1500, // Adjust this value as needed
        whiteSpace: 'normal', // Allows text to wrap
      },
    },
  };

const stackStyles: IStackStyles = {
    root: {
        padding: 0
    },
};

const ListPaneComponent = (props: { horizontalReSizer: any }) => {
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const store = useStore();
    const workerButtonId = 'worker';
    const toolButtonId = 'tool';

    const [selection, setSelection] = useState<Selection | undefined>();
    const [contextMenuProps, setContextMenuProps] = useState<{ [key: string]: any } | undefined>(undefined);
    const [showAbsencePopUp, setShowAbsencePopUp] = useState(false);
    const [leaveTypes , setLeaveTypes] = useState([]);
    const [selectedPerson , setSelectedPerson] = useState<any>(null);
    const workSchedule = [
        {
          id: uuidv4(),
          day: i18n.t('monday'),
          startTime: '',
          endTime: '',
          displayOrder: '1',
        },
        {
          id: uuidv4(),
          day: i18n.t('tuesday'),
          startTime: '',
          endTime: '',
          displayOrder: '2',
        },
        {
          id: uuidv4(),
          day: i18n.t('wednesday'),
          startTime: '',
          endTime: '',
          displayOrder: '3',
        },
        {
          id: uuidv4(),
          day: i18n.t('thursday'),
          startTime: '',
          endTime: '',
          displayOrder: '4',
        },
        {
          id: uuidv4(),
          day: i18n.t('friday'),
          startTime: '',
          endTime: '',
          displayOrder: '5',
        },
        {
          id: uuidv4(),
          day: i18n.t('saturday'),
          startTime: '',
          endTime: '',
          displayOrder: '6',
        },
        {
          id: uuidv4(),
          day: i18n.t('sunday'),
          startTime: '',
          endTime: '',
          displayOrder: '7',
        },
      ]

    

    const columns = [
        {
            key: 'column1',
            name: t('date'),
            fieldName: 'day',
            minWidth: 100,
            maxWidth: 125,
            isResizable: true,
        },
        {
            key: 'column2',
            name: t('workers'),
            fieldName: 'workers',
            minWidth: 300,
            maxWidth: 1050,
            isResizable: true,
        },
        {
            key: 'column3',
            name: t('vehicle'),
            fieldName: 'vehicle',
            minWidth: 300,
            maxWidth: 500,
            isResizable: true,
        },
    ];

    const toolColumns = [
        {
            key: 'column1',
            name: t('date'),
            fieldName: 'day',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: 'column2',
            name: t('tools'),
            fieldName: 'tool',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        }
    ];

    const {
        resourceList,
        view,
        selectedDate,
        selectedWeek,
        isResourceDataLoaded,
        mydPlanResourceType,
        reloadBottomListPane,
        isDataLoaded
    } = useSelector(
        (state: any) => state.mydPlan
    );

    const {userInfo} = useSelector(
        (state: any) => state.uprince
    );

    const {buDropDown, selectedCu, selectedBu} = useSelector(
        (state: any) => state.projectDPlan
    );
    const createNewPmolSuccess = useSelector((state: any) => state?.dplan?.createNewPmolSuccess);

    const prevView: any = usePrevious<any>(view);
    const prevDate: any = usePrevious<any>(selectedDate);
    // const prevStartDate: any = usePrevious<any>(selectedWeek.startDate);

    useEffect(() => {
        // dispatch(filterMyDayPlanningResourceListPaneData(getFilterDate(true)));

        const subscription = messageService.getMessage().subscribe((data: any) => {
            if (data) {

            }
        });

        return () => {
            subscription.unsubscribe();
            // console.log('willUnmount')
        }
    }, []);

    // useEffect(() => {
    //     if (reloadBottomListPane) {
    //         dispatch(filterMyDayPlanningResourceListPaneData(getFilterDate()));
    //
    //         return () => {
    //             dispatch(saveMyDayPlanningStateAttr('reloadBottomListPane', false));
    //         }
    //     }
    // }, [reloadBottomListPane]);

    useEffect(() => {
        if (prevView && prevView !== view) {
            dispatch(filterMyDayPlanningResourceListPaneData(getFilterDate()));
        }
    }, [view]);

    useEffect(() => {
        if (prevDate && moment(prevDate).format('YYYY-MM-DD') !== moment(selectedDate).format('YYYY-MM-DD')) {
            dispatch(filterMyDayPlanningResourceListPaneData(getFilterDate()));
        }
    }, [selectedDate]);

    useEffect(() => {
        if (!_.isEmpty(selectedWeek)) {
            // console.log('selectedWeek.startDate> ', selectedWeek.startDate);
            // console.log('prevStartDate> ', prevStartDate);
            dispatch(filterMyDayPlanningResourceListPaneData(getFilterDate()));
        }
    }, [selectedWeek]);

    useEffect(() => {
        if (resourceList) {
            getResourceList(resourceList);
        }
    }, [resourceList]);

    useEffect(() => {
        if (selectedCu && selectedBu && !getProject()) {
            dispatch(filterMyDayPlanningResourceListPaneData(getFilterDate()));
        }
    }, [selectedCu, selectedBu]);

    useEffect(() => {
        if (createNewPmolSuccess) {
            dispatch(filterMyDayPlanningResourceListPaneData(getFilterDate()));
        }

        return () => {
            dispatch(saveDayPlanningStateAttr('createNewPmolSuccess', false));
        }
    }, [createNewPmolSuccess]);

    const getFilterDate = (didMount?: boolean) => {
        const currentDate = moment();

        if (didMount) {
            return {
                startDate: moment(store.getState()?.mydPlan?.selectedDate).format('YYYY-MM-DD'),
                endDate: moment(store.getState()?.mydPlan?.selectedDate).clone().format('YYYY-MM-DD'),
                localDate: moment(currentDate).format('YYYY-MM-DD'),
                offSet: getTimeZone(),
                type: GridView.DAY,
                contractingUnit: getProject() ? store.getState()?.projectDPlan?.selectedCu?.sequenceCode : userInfo ? userInfo?.contractingUnit?.sequnceCode : [],
                buId: getProject() ? store.getState()?.projectDPlan?.selectedBu : buDropDown ? buDropDown[0]?.key : null,
            }
        } else {
            return {
                startDate: store.getState()?.mydPlan?.view === GridView.DAY
                    ? moment(store.getState()?.mydPlan?.selectedDate).format('YYYY-MM-DD')
                    : store.getState()?.mydPlan?.selectedWeek.startDate ? moment(store.getState()?.mydPlan?.selectedWeek.startDate).format('YYYY-MM-DD') : moment(currentDate).startOf('isoWeek').format('YYYY-MM-DD'),
                endDate: store.getState()?.mydPlan?.view === GridView.DAY
                    ? moment(store.getState()?.mydPlan?.selectedDate).format('YYYY-MM-DD')
                    : store.getState()?.mydPlan?.selectedWeek.endDate ? moment(store.getState()?.mydPlan?.selectedWeek.endDate).format('YYYY-MM-DD') : moment(currentDate).clone().endOf('isoWeek').format('YYYY-MM-DD'),
                localDate: moment(currentDate).format('YYYY-MM-DD'),
                offSet: getTimeZone(),
                type: store.getState()?.mydPlan?.view,
                contractingUnit: store.getState()?.projectDPlan?.selectedCu ? store.getState()?.projectDPlan?.selectedCu?.sequenceCode : userInfo ? userInfo?.contractingUnit?.sequnceCode : [],
                buId: store.getState()?.projectDPlan?.selectedBu ? store.getState()?.projectDPlan?.selectedBu : null,
            }
        }
    };

    const getResourceList = (resourceList: any) => {
        return resourceList;
    };

    const onClickItem = (item: any) => {

    }

    const formatwithoutTime = (date: moment.MomentInput) => {
        const formatted = moment.utc(date).local().format('dddd MMM DD');
        return formatted === 'Invalid date' ? '' : formatted;
    };

    const getWorkerIconColor = (precentage: any): string => {
        const getDouble = parseFloat(precentage);

        if (0.0 <= getDouble && getDouble <= 80) {
            return classNames.red;
        } else if (80 < getDouble && getDouble <= 110) {
            return classNames.green;
        } else if (110 < getDouble) {
            return classNames.orange;
        }

        return classNames.deepSkyBlue
    };

    const getColorBasedOnWorkHours = (assignTime: number) => {
        if(0<assignTime && assignTime<8 ){
            return "red";
        } else if(8<=assignTime){
            return "green"
        } else if(assignTime==0 || !assignTime){
            return "grey";
        }
    }

    const onItemContextMenu = (e: ReactMouseEvent<HTMLDivElement>, item: any) => {
        setSelectedPerson(item);
        if(!leaveTypes || leaveTypes.length===0){
            Promise.all([dispatch(getAbsenceLeaveTypeDropdown())]).then((response: any) =>{
                console.log(response[0].payload.data.result);
                setLeaveTypes(response[0].payload.data.result);
            })
        }
        e.preventDefault();
        setContextMenuProps({
            target: e.currentTarget,
            items: [
                {
                    key: 'registerLeave',
                    text: t('register Leave'),
                    onClick: () => handleRegisterLeave(item),
                }
            ],
            directionalHint: DirectionalHint.rightCenter
        });
    };

    const handleRegisterLeave = (item: any) => {
        setContextMenuProps(undefined); // Hide the menu after action
        setShowAbsencePopUp(true); // open the absence popup
    };

    const openAbsenceModal = (): void => {
        setShowAbsencePopUp(false);
      };
    
      const onChangeStartTime = (index: number, value: any) => {
          let schedule = workSchedule;
          let task = schedule[index];
          task.startTime = value;
          console.log(schedule);

      };
    
      const onChangeEndTime = (index: number, value: any) => {
        // if (
        //   this.state.workSchedule &&
        //   !_.isEmpty(this.state.workSchedule) &&
        //   this.state.workSchedule.length > index
        // ) {
          let schedule = workSchedule;
          let task = schedule[index];
          task.endTime = value;
          console.log(schedule);
        //   this.setState(
        //     (prevState: any) => ({
        //       workSchedule,
        //       isClear: false,
        //     }),
        //     () => {
        //     },
        //   );
        // }
      };

      const onCreateAbsence = (absence: any) => {
        if (!absence.id) {
          absence.id = uuidv4();
        }
           console.log('Absence data',absence);
           Promise.all([dispatch(createAbsence(absence))]).then((response: any) => {
                dispatch(saveMyDayPlanningStateAttr('absenceUpdateSucces', true));
           })
      };

      const getTooltipDataStructure = (projectLists: any) => {
        if(projectLists){
            const structuredData = projectLists.map((project: any, index: number) => {
                const pbsDetails = project?.products?.map((product: any) => {
                    const pmolDetails =  product?.pmols?.map((pmol: any) => {
                        return <><li style={{paddingLeft: 20, paddingTop:10}}>{pmol?.pmolTitle}</li></>
                    })
                    return <><li style={{paddingLeft: 10, paddingTop:10}}>{product?.productTitle}</li>
                                {pmolDetails}
                            </>
                })
                return <><li style={{paddingTop:index==0?0:10}}>{project?.projectTitle}</li>
                            {pbsDetails}
                       </>
            })
            return structuredData;
        }
      } 

    const resourceListColumn = (item: any, index?: number, column?: IColumn) => {        
        if (column && column.fieldName && (column.fieldName === 'workers')) {
            const fieldContent = item[column.fieldName];
            return fieldContent ? <span style={{paddingLeft: 9}} onClick={() => {
            }}>
                <Stack horizontal wrap styles={stackStyles} tokens={wrapStackTokens}>
                    {fieldContent.map((worker: any) => {
                        const tooltipData = getTooltipDataStructure(worker?.projectLists);
                        return <div key={worker.id} style={{marginRight:'0.5%'}} onContextMenu={(e) => onItemContextMenu(e, worker)}>
                            <TooltipHost
                                delay={TooltipDelay.medium}
                                id={worker?.projectList}
                                directionalHint={DirectionalHint.topCenter}
                                styles={hostStyles}
                                calloutProps={calloutProps}
                                content={tooltipData?.length ? (
                                  <>{tooltipData}</>
                                ) : (
                                  <span>{t('noAssignedProjects')}</span>
                                )}
                            >
                                <DragBox
                                    // disabled={worker.absence ? true : !getProject()}
                                    type={AcceptType.TEAM}
                                    onClick={(item: any) => onClickItem(item)}
                                    item={{...worker, day: item['day']}}
                                    name={worker?.name}
                                    icon={!worker?.absence
                                        ? 
                                        // <FontIcon aria-label='Worker' iconName='Contact'
                                        //             className={getWorkerIconColor(worker?.precentage)}/>
                                        ((worker.assignTime==0 || !worker.assignTime)? 
                                    //     <span>
                                    //         <svg
                                    //         xmlns="http://www.w3.org/2000/svg"
                                    //         width="16"
                                    //         height="16"
                                    //         stroke="grey"
                                    //         className="bi bi-person-fill"
                                    //         viewBox="2 0 14 14"
                                    //         fill="none" // Ensures the inside remains uncolored
                                    //         >
                                    //         <path
                                    //             d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"
                                    //             stroke="grey"
                                    //         />
                                    //         </svg>
                                    //   </span>
                                    <FontIcon aria-label='Worker' iconName='Contact' className={classNames.grey} style={{marginBottom:5}}/>
                                            :<span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={getColorBasedOnWorkHours(worker.assignTime)} className="bi bi-person-fill" viewBox="2 0 14 14">
                                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                                                </svg>
                                            </span>)
                                        : <FontIcon aria-label='Sick' iconName='WarningSolid' className={classNames.red} style={{ color: iconColors.warningIconColor }} />
                                    }
                                />
                            </TooltipHost>                            
                        </div>
                    })}
                    {contextMenuProps && (
                        <ContextualMenu
                            items={contextMenuProps.items}
                            target={contextMenuProps.target}
                            onDismiss={() => setContextMenuProps(undefined)}
                            directionalHint={contextMenuProps.directionalHint}
                        />
                    )}
                </Stack>
            </span> : <span style={{paddingLeft: 9}}>-</span>;
        } else if (column && column.fieldName && (column.fieldName === 'vehicle')) {
            const fieldContent = item[column.fieldName];           
            return fieldContent ? <span style={{paddingLeft: 9}} onClick={() => {
            }}>
                <Stack horizontal wrap styles={stackStyles} tokens={wrapStackTokens}>
                    {fieldContent.map((vehicle: any) => {
                        const vehicleTitle = vehicle.resourceNumber&&vehicle.title ? vehicle.title.substring(vehicle.resourceNumber.length+3).trim() : vehicle.title;
                        return <div key={Math.random()}>
                            <DragBox
                                // disabled={!getProject()}
                                type={AcceptType.VEHICLE}
                                onClick={(item: any) => onClickItem(item)}
                                item={{...vehicle, day: item['day'],fromTeamId: null}}
                                name={vehicleTitle}
                                icon={<FontIcon aria-label="Compass" iconName="DeliveryTruck"
                                                className={classNames.deepSkyBlue}/>}
                            />
                        </div>
                    })}
                </Stack>
            </span> : <span style={{paddingLeft: 9}}>-</span>;
        } else if (column && column.fieldName && (column.fieldName === 'day')) {
            const fieldContent = item[column.fieldName];
            return fieldContent ? <span style={{paddingLeft: 9}} onClick={() => {
            }}>
                {formatwithoutTime(fieldContent)}
            </span> : <span style={{paddingLeft: 9}}>-</span>;
        } else if (column && column.fieldName) {
            const fieldContent = item[column.fieldName];
            return fieldContent ? <span style={{paddingLeft: 9}} onClick={() => {
            }}>{fieldContent}</span> : <span style={{paddingLeft: 9}}>-</span>;
        }
        return <span style={{paddingLeft: 9}}>-</span>;
    };

    const resourceToolsListColumn = (item: any, index?: number, column?: IColumn) => {
        if (column && column.fieldName && (column.fieldName === 'tool')) {
            const fieldContent = item[column.fieldName];
            return fieldContent ? <span style={{paddingLeft: 9}} onClick={() => {
            }}>
                <Stack horizontal wrap styles={stackStyles} tokens={wrapStackTokens}>
                    {fieldContent.map((worker: any) => {
                        const toolName = worker.resourceNumber&&worker.title ? worker.title.substring(worker.resourceNumber.length+3).trim() : worker.title;
                        return <div key={Math.random() + "tool"}>
                            <DragBox
                                // disabled={worker.absence ? true : !getProject()}
                                type={AcceptType.TOOL}
                                onClick={(item: any) => onClickItem(item)}
                                item={{...worker, day: item['day']}}
                                name={toolName}
                                icon={<FontIcon aria-label="Compass" iconName="DeveloperTools"
                                                className={worker.absence ? classNames.red : classNames.deepSkyBlue}/>}
                            />
                        </div>
                    })}
                </Stack>
            </span> : <span style={{paddingLeft: 9}}>-</span>;
        } else if (column && column.fieldName && (column.fieldName === 'day')) {
            const fieldContent = item[column.fieldName];
            return fieldContent ? <span style={{paddingLeft: 9}} onClick={() => {
            }}>
                {formatwithoutTime(fieldContent)}
            </span> : <span style={{paddingLeft: 9}}>-</span>;
        } else if (column && column.fieldName) {
            const fieldContent = item[column.fieldName];
            return fieldContent ? <span style={{paddingLeft: 9}} onClick={() => {
            }}>{fieldContent}</span> : <span style={{paddingLeft: 9}}>-</span>;
        }

        return <span style={{paddingLeft: 9}}>-</span>;
    };

    const displayMessage = () => {
        return (
            <div style={{paddingTop: 36, paddingLeft: 20}}>
                <Text>{t('loadMsg')}</Text>
            </div>
        );
    };

    return (
        <div className={`wrapper-holder`}
            //  style={{position: 'relative', height: screen.height - (props.horizontalReSizer + 365), overflow: 'auto'}}>
            style={{position: 'relative', height: screen.height - 765, overflow: 'auto'}}>
            {/* <pre>  {JSON.stringify(resourceList, 0, 2)}</pre> */}
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}> 
                <ShimmeredDetailsList
                    items={resourceList ?? []}
                    columns={mydPlanResourceType === workerButtonId ? columns : toolColumns}
                    setKey="set"
                    enableShimmer={!isResourceDataLoaded}
                    // layoutMode={mydPlanResourceType === workerButtonId ? DetailsListLayoutMode.fixedColumns : DetailsListLayoutMode.justified}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionPreservedOnEmptyClick={true}
                    checkboxVisibility={1}
                    selection={selection}
                    selectionMode={SelectionMode.none}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="Row checkbox"
                    onRenderDetailsHeader={(headerProps, defaultRender) => {
                        if (defaultRender !== undefined) {
                            return (
                                <Sticky
                                    stickyPosition={StickyPositionType.Header}
                                    isScrollSynced={true}
                                    stickyBackgroundColor="transparent"
                                >
                                    <div>{defaultRender(headerProps)}</div>
                                </Sticky>
                            );
                        } else {
                            return <span></span>;
                        }
                    }}
                    onRenderItemColumn={(
                        item: any,
                        index?: number,
                        column?: IColumn,
                    ) =>
                        mydPlanResourceType === workerButtonId ? resourceListColumn(item, index, column) : resourceToolsListColumn(item, index, column)
                    }
                />
                {isDataLoaded && displayMessage()}
            </ScrollablePane>
            <AbsenceModal
                isOpen={showAbsencePopUp}
                isEdit={false}
                openAbsenceModal={ openAbsenceModal}
                title={i18n.t('absence')}
                labourHistorySelectionDetails={
                    [] as AbsenceModalDTO[]
                }
                onChangeStartTime={(index: number, value: string | number) =>
                    onChangeStartTime(index, value)
                }
                onChangeEndTime={(index: number, value: string | number) =>
                    onChangeEndTime(index, value)
                }
                createAbsence={(absence: any) => {
                    onCreateAbsence(absence);
                }}
                labourPerson={{cabPersonId:'',email:'', key:selectedPerson?.id, mobile:'',Organisation:'',secondaryText:'', text:selectedPerson?.name}}
                leaveTypes={leaveTypes}
            />
        </div>
    );
};

export default ListPaneComponent;

import {
  ActionButton,
  ContextualMenu,
  FontWeights,
  getTheme,
  IconButton,
  IDragOptions,
  IIconProps,
  IStackItemStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Modal,
  Separator,
  Stack,
  Dropdown,
  DetailsList,
  SelectionMode,
  DetailsListLayoutMode,
  IColumn,
  Sticky,
  StickyPositionType,
  IDetailsFooterProps,
  DetailsRow,
  IDetailsRowBaseProps,
  DetailsRowCheck,
  IDetailsRowCheckStyles,
  Selection
} from '@fluentui/react';
import { uPrinceTheme } from '../../../../../theme';
import React, { useEffect, useRef, useState } from 'react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { PickListItem } from '../../../../types/pickListItem';
import { useTranslation } from 'react-i18next';
import TaxonomyField from '../../certificationTaxonomy/taxonomy';
import { CertificationDropDowns, CertificationTaxonomy, CountryDropDowns } from '../../../../types/certification';
import { formatDateTimeToISOString, getBreadcrumbItemFromData, getContractingUnit, getProject } from '../../../../shared/util';
import { Field, Form } from 'react-final-form';
import DatePickerRequiredAdapter from '../../../../shared/fluentFinalForm/DatePickerRequiredAdapter';
import { TextFieldFF, DropDownAdaptater } from '../../../../shared/fluentFinalForm';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { uploadInstructionDoc } from '../../../../reducers/contractReducer';
import DragAndDropFileUpload from '../../../../shared/Dropzone/FileDropZone';
import { getcountry } from '../../../../reducers/addressBookReducer';
import { getFilterProjectList } from '../../../../reducers/projectReducer';
import { Input } from '@fluentui/react-components';
import ProductTaxonomyField from '../../../ProjectBreakdownStructure/taxonomy/productTaxonomy';
import client from '../../../../api';
import { readByHumanResourceId } from '../../../../reducers/humanResourceReducer';
import style from './style.css'

const theme = getTheme();

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};
const stackEditbarItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 45,
    alignItems: 'flex-end',
  },
};
const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };
const editIcon: IIconProps = { iconName: 'Edit', styles: iconButtonStyles };
const removeIcon: IIconProps = {
  iconName: 'Delete',
  styles: iconButtonStyles,
};

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
  middiumwidth: { width: '80%' },
  halfWidth: { width: '49%' },
});

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionItemsCont: {
    
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '24px 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '40vw',
    minHeight: '20vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const stackStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    flexBasis: '50%',
    height: 50,
    padding: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 0,
};

const stackItemStyles: IStackItemStyles = {
  root: {
    padding: 0,
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const uploadIconWhite: IIconProps = {
  iconName: 'Upload',
  styles: addWhiteIconButtonStyles,
};

export type CertificationModalDTO = {
  id: string | null;
  personId: string | undefined;
  startDate: string | null;
  endDate: string | null;
  certificationTaxonomyId: string | null;
  countryId:string|null
};


interface CertificationFormPayloads {
  id: any,
  countryId: string | null,
  projectDefinitionId: string, // []
  productId: string | null, // newly added for selecting product
  productTitle: string | null // newly added for selecting product
  certificationTaxonomyId: string | null,
  certificationUrl: string | null,
  certificationTitle: string | null,
  startDate: string | null,
  endDate: string | null,
  personId: string | null,
}


const CertificationModal = (props: {
  isOpen: boolean;
  isEdit: boolean;
  openCertificationModal: () => void;
  options?: PickListItem[];
  allOptions?: PickListItem[];
  onAddContact?: (certificate: any, saveAndNew: boolean) => void;
  title: string;
  valueLable: string;
  organisationSelectionDetails: CertificationModalDTO[] | any;
  isChecking: boolean;
  isUniq: number;
  isQniqContact: any;
  dropDowns: CertificationDropDowns;
  // isCountry: CountryDropDowns;
  certificationTaxonomyList: CertificationTaxonomy[];
  certificationTaxonomyId: string | null;
  setCertificationTaxonomyId: (certificationTaxonomyId: string | null) => void;
  createCabCertification: (certificate: any) => void;
  triggerCertificationSavedToTrue?: any
  // countries:{ key: string; text: string }[];
}) => {
  // constants
  const { t } = useTranslation();
  const [isDraggable] = useBoolean(false);
  const titleId = useId('title');
  const defaultCountryKey = 'b3e1d1e0-6b7d-4f8a-8f79-43dbcbab1324';
  const countries = useSelector((state: any) => state.addressBook);
  const project = useSelector((state: any) => state.project);
  const countryOptions = Array.isArray(countries.isCountry)
    ? countries.isCountry.map((country: any) => ({ key: country.countryId, text: country.countryName }))
    : [];
    const projectOptions = Array.isArray(project.allProjects)
    ? project.allProjects.map((proj: any) => ({ key: proj.value, text: `${proj.sequenceCode}-${proj.name}` }))
    : [];
  const inputFile: any = useRef(null);
  const dispatch = useDispatch();
  const currentUrl = window.location.href

  // states
  const [isValid, setIsValid] = useState<boolean>(false);
  // const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const [personid, setPersonid] = useState<any>()
  const [certificate, setCertificate] = useState<CertificationModalDTO>({
    id: '',
    personId: '',
    startDate: '',
    endDate: '',
    certificationTaxonomyId: '',
    // projectDefinitionIds:[],
    countryId:'',
    // productId: '',
  });
  const [formRow, setFormRow] = useState<CertificationFormPayloads[]>([
    {
      id: uuidv4(),
      countryId: defaultCountryKey,
      certificationTaxonomyId: "",
      certificationTitle: "",
      certificationUrl:"",
      startDate: "",
      endDate: "",
      personId: "",
      projectDefinitionId: "",
      productId: "",
      productTitle: ""
    }
  ])
  const [selectedFormRow, setSelectedFormRow] = useState<any[]>([]);

  useEffect(() => {
    getPersonIdByUrl(currentUrl)
  }, [currentUrl])

  const getPersonIdByUrl = async (url: string) => {
    const urlObj = new URL(url);
    const pathname = urlObj?.pathname;
    const segments = pathname?.split('/');

    // Retrieve the last segment
    const lastSegment: any = segments?.pop();
    try {
      const res: any = await dispatch(readByHumanResourceId(lastSegment))
      if (res?.payload?.status === 200) {
        setPersonid(res.payload.data.result.cabPersonId)
      }
    } catch (error) {
      console.log('Error', error)
    }
  }

  // LCM
  useEffect(() => {
    dispatch(getcountry());
    dispatch(getFilterProjectList(
      {
        title: null,
        projectTypeId: null,
        managementLevelId: null,
        toleranceStateId: null,
        projectManagerId: null,
        projectStatus: null,
        customerId: null,
        siteManagerId: null,
        templateId: null,
        projectClassificationSectorId: null,
        ciawNumber: null,
        projectAddress: null,
        history: false,
        sorter: {
            "attribute": null,
            "order": null
        },
        customerCompanyName: null,
        customerCompanyId: null
      }
    ))

  }, [dispatch]);
  
  useEffect(() => {
    if (props.isEdit && !props.organisationSelectionDetails[0]) {
      setIsValid(false);
    }

    if (props.isEdit === true) {
      const editData = {
        id: props.organisationSelectionDetails[0].id,
        productId: props.organisationSelectionDetails[0].pbsProductId,
        productTitle: props.organisationSelectionDetails[0].pbsProductTitle,
        certificationTaxonomyId: props.organisationSelectionDetails[0].certificationTaxonomyId,
        certificationTitle: props.organisationSelectionDetails[0].certificationTitle,
        certificationUrl: props.organisationSelectionDetails[0].certificationUrl,
        startDate: props.organisationSelectionDetails[0].startDate,
        endDate: props.organisationSelectionDetails[0].endDate,
        personId: props.organisationSelectionDetails[0].personId,
        countryId: props.organisationSelectionDetails[0].countryId,
        projectDefinitionId: props.organisationSelectionDetails[0].projectDefinitionId
      }
      setFormRow([editData])
    } else {
      setFormRow([{
        id: uuidv4(),
        countryId: defaultCountryKey,
        certificationTaxonomyId: "",
        certificationTitle: "",
        certificationUrl:"",
        startDate: "",
        endDate: "",
        personId: "",
        projectDefinitionId: "",
        productId: "",
        productTitle: ""
      }])
    }
  }, [props.isEdit]);

  useEffect(() => {
    if (props.isOpen && !props.organisationSelectionDetails[0]) {
      setIsValid(true);
    }
  }, [props.isOpen]);

  useEffect(() => {
    setCertificate(props.organisationSelectionDetails[0]);
    setIsValid(true);
  }, [props.organisationSelectionDetails]);

  const getInitialFormValues = (formData: any) => {
    if (formData && formData?.id) {
      return {
        id: formData.id,
        personId: formData.personId,
        startDate: formData.startDate ? new Date(formData.startDate) : null,
        endDate: formData.endDate ? new Date(formData.endDate) : null,
        certificationTaxonomyId: formData.certificationTaxonomyId,
        certificationTitle: formData?.certificationTitle,
        certificationUrl: formData?.certificationUrl,
        countryId:formData?.countryId,
        projectDefinitionIds:formData.projectDefinitionId ? [formData.projectDefinitionId] : [],
      };
    }
    return {
      countryId:formData?.countryId || defaultCountryKey
    }
  };

  const onSubmit = async (values: any) => {
    let formValues: CertificationFormPayloads[]
    if (props.isEdit === true) {
      formValues = formRow.map((each: CertificationFormPayloads) => {
        return {
          ...each,
          id: values?.id,
          certificationTaxonomyId: values.certificationTaxonomyId,
          certificationUrl: values.certificationUrl,
          certificationTitle: values.certificationTitle,
          startDate: values.startDate,
          endDate: values.endDate,
          countryId: values.countryId,
          personId: personid
        }
      })
      props.createCabCertification(formValues);
    } else {
      formValues = formRow.map((each: CertificationFormPayloads) => {
        return {
          ...each,
          id: each.id,
          certificationTaxonomyId: values.certificationTaxonomyId,
          certificationUrl: values.certificationUrl,
          certificationTitle: values.certificationTitle,
          startDate: values.startDate,
          endDate: values.endDate,
          countryId: values.countryId,
          personId: personid
        }
      })
      const updatedFormValues = formValues.map(({ id, ...rest }) => rest);
      props.createCabCertification(updatedFormValues);
    }
    // values.projectSequenceCode = getProject();
    props.openCertificationModal();
    setIsValid(false);
    setFormRow([]);
    // if (!values.id) {
    //     values.id = props.uid;
    // }
    // props.saveFormData(values);
  };


  const [files, setFiles] = useState<File[]>([]);


  const handleFileDrop = (acceptedFiles: File[], form: any) => {
    const formData = new FormData();
    if (acceptedFiles.length > 0) {
      formData.append('file', acceptedFiles[0]);
      formData.append('id', uuidv4());

      // Dispatch the file upload action
      Promise.all([
        dispatch(uploadInstructionDoc(formData))
      ]).then((response: any) => {
        if (response[0]?.payload?.data?.result) {
          // Set the certification URL in the form
          form.mutators.setCertificationUrl(response[0]?.payload?.data?.result);
        } else {
          form.mutators.setCertificationUrl(null);
        }
      });
    }
  };

  // const handleDropdownChange = (item:any, form:any) => {
    // const currentValues = form.getFieldState('projectDefinitionIds')?.value || [];
    // if (item.selected) {
    //   form.mutators.setFieldValue([...currentValues, item.key]);
    // } else {
    //   form.mutators.setFieldValue(currentValues.filter((key:any) => key !== item.key));
    // }
    
  // };

  const handleDropdownChange = (item: any, index: any) => {
    const updatedFormRow = [...formRow]; // Create a copy of the formRow array
    updatedFormRow[index] = {
      ...updatedFormRow[index],
      projectDefinitionId: item.key // Update the specific field with the selected value
    };

    setFormRow(updatedFormRow); // Update the state with the new formRow array
  }

  const handleCheckboxChange = (item: any) => {
    setSelectedFormRow(prevSelected =>
      prevSelected.includes(item.id)
        ? prevSelected.filter(itemId => itemId !== item.id) // Remove item if it was already selected
        : [...prevSelected, item.id] // Add item ID if it was not selected
    );
  }
 
  const columnForm = [
    {
      key: 'column4',
      name: "",
      fieldName: 'checkbox',
      minWidth: 30,
      maxWidth: 30,
      onRender: (item: any) => (
        <input
          type="checkbox"
          checked={item.isSelected}
          style={{width: 16, height: 16}}
          onChange={() => handleCheckboxChange(item)}
        />
      ),
      // isResizable: true,
    },
    {
      key: 'column1',
      name: t('project'),
      fieldName: 'projectDefinitionIds',
      minWidth: 100,
      maxWidth: 220,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('product'),
      fieldName: 'productId',
      minWidth: 100,
      // maxWidth: 400,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('Action'),
      fieldName: 'action',
      minWidth: 100,
      // maxWidth: 200,
    },
  ];

  const detailsRowCheckStyles: Partial<IDetailsRowCheckStyles> = {
    root: { visibility: 'hidden' },
  };

  // Create a selection object
  // const handleSelection = (event: any, item: any) => {
  //   const isChecked = event.target.checked;

  //   if (isChecked) {
  //     // Add item if checked and not already selected
  //     setSelectedFormRow((prev: any) => [...prev, item]);
  //   } else {
  //     // Remove item if unchecked
  //     setSelectedFormRow((prev: any) => prev.filter((selectedItem: any) => selectedItem.id !== item.id));
  //   }
  // };

  const selection = new Selection({
    onSelectionChanged: () => {
      const selectedItems = selection.getSelection();
      setSelectedFormRow(selectedItems)
    }
  });

  const onDeleteRow = () => {
    if (formRow.length > 0) {
      const idsToRemove = new Set(selectedFormRow);
      const newArray = formRow.filter(row => !idsToRemove.has(row.id));
      setFormRow(newArray);
      setSelectedFormRow([]); // Clear selected items after deletion
    }
  }

  const getProjectSequenceCode = (value: any) => {
    const copy = project?.allProjects
    const filtered = copy.filter((e: any) => e.value === value);

    return filtered[0]?.sequenceCode
  }

  const _renderDetailsHeaderItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] =
    (item, index, column) => {
      if (column && column.fieldName && column.fieldName === 'project') {
        return (
          <div style={{ marginTop: 20 }}>
            <b>{t('project')}</b>
          </div>
        );
      } else if (
        column &&
        column.fieldName &&
        column.fieldName === 'product'
      ) {
        return (
          <div style={{ width: 400, paddingLeft: 2 }}>
            &nbsp;
            <Label>{t('product')}</Label>
          </div>
        );
        return null;
      } 
      else if (
        column &&
        column.fieldName &&
        column.fieldName === 'action'
      ) {
        return (
          <div style={{ width: 400, paddingLeft: 2 }}>
            &nbsp;
            <Label>{t('Action')}</Label>
          </div>
        );
        return null;
      } 
      // else if (
      //   column &&
      //   column.fieldName &&
      //   column.fieldName === 'checkbox'
      // ) {
      //   return (
      //     <div style={{paddingLeft: 2 }}>
      //       &nbsp;
      //       <Label></Label>
      //     </div>
      //   );
      //   return null;
      // } 
    };

    // const handleSelectAll = (e: any, item: any) => {
    //   const isChecked = e.target.checked;
  
    //   if (isChecked) {
    //     // Add all items to selectedFormRow
    //     setSelectedFormRow(formRow);
    //   } else {
    //     // Clear selectedFormRow
    //     setSelectedFormRow([]);
    //   }
    // };

    const _onRenderCheckForHeaderRow: IDetailsRowBaseProps['onRenderCheck'] = (
      props,
    ): JSX.Element => {
      return (
        <DetailsRowCheck
          {...props}
          styles={detailsRowCheckStyles}
          selected={true}
        />
      );
    };

  const _onRenderDetailsHeader = (
    detailsFooterProps: IDetailsFooterProps,
  ): JSX.Element => {
    return (
      <DetailsRow
        {...detailsFooterProps}
        columns={detailsFooterProps.columns}
        item={{}}
        itemIndex={-1}
        groupNestingDepth={detailsFooterProps.groupNestingDepth}
        selectionMode={SelectionMode.single}
        selection={detailsFooterProps.selection}
        onRenderItemColumn={_renderDetailsHeaderItemColumn}
        onRenderCheck={_onRenderCheckForHeaderRow}
      />
    );
  };
  
  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          props.openCertificationModal();
        }}
        isBlocking={true}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>
            {props.isEdit
              ? t('edit') + ' ' + props.title
              : t('add') + ' ' + props.title}
          </span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              props.openCertificationModal();
              if (!props.organisationSelectionDetails[0]) {
                setIsValid(false);
              }
            }}
          />
        </div>

        <div className={contentStyles.body}>
          <div className="proj-detail-block">
            <Form
              onSubmit={onSubmit}
              initialValues={getInitialFormValues(certificate)}
              // initialValues={formRow}
              mutators={{
                setCertificationUrl: (args, state, utils) => {
                  const field = state.fields.certificationUrl;
                  field.change(args[0]);
                },
                setFieldValue: (args, state, utils) => {
                  const field = state.fields.projectDefinitionIds;
                  field.change(args[0]);
                },
                setProdId: (args, state, utils) => {
                  const field = state.fields.productId;
                  field.change(args[0]);
                },
              }}
              validate={values => {
                const errors: any = {};
                if (!values?.startDate) {
                  errors.startDate = t('startDateRequired');
                }
                if (!values?.endDate) {
                  errors.endDate = t('endDateRequired');
                }
                return errors;
              }}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                errors,
                values,
              }) => {
                return (
                  <form onSubmit={handleSubmit} noValidate>
                    
                    <div className="ms-Grid-row">
                      <Stack
                        horizontal
                        horizontalAlign="end"
                        styles={stackStyles}
                        tokens={stackTokens}
                      >
                        <Stack.Item styles={stackItemStyles}>
                          <Label style={{ marginTop: 10 }}>1. Product Details</Label>
                        </Stack.Item>
                        {/* {!props.readOnly && ( */}
                          <Stack.Item styles={stackEditbarItemStyles}>
                            <ActionButton
                              className={classNames.actionButton}
                              // disabled={props.organisationActionButtonState.add}
                              iconProps={addIcon}
                              allowDisabledFocus
                              onClick={() => {
                                setFormRow([...formRow, {
                                  id: uuidv4(),
                                  countryId: values?.countryId || defaultCountryKey,
                                  projectDefinitionId: "",
                                  certificationTaxonomyId: "",
                                  certificationTitle: "",
                                  certificationUrl: "",
                                  startDate: "",
                                  endDate: "",
                                  personId: "",
                                  productId: "",
                                  productTitle: ""
                                }])
                              }}
                              disabled={props.isEdit === true}
                            >
                              <Label className={classNames.actionButton}>{t('add')} </Label>
                            </ActionButton>
                            <ActionButton
                              iconProps={editIcon}
                              // disabled={props.organisationActionButtonState.edit}
                              allowDisabledFocus
                              onClick={() => {
                                // props.openModel();
                              }}
                              disabled
                            >
                              <Label className={classNames.actionButton}> {t('edit')}</Label>
                            </ActionButton>
                            <ActionButton
                              iconProps={removeIcon}
                              disabled={formRow?.length > 1 ? false : true}
                              allowDisabledFocus={formRow?.length > 1 ? false : true}
                              onClick={onDeleteRow}
                            >
                              <Label className={classNames.actionButton}>
                                {t('remove')}
                              </Label>
                            </ActionButton>
                          </Stack.Item>
                        {/* )} */}
                      </Stack>
                    </div>
                      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className={classNames.wrapper} style={formRow?.length > 2 ? {height: '200px', overflow: 'auto'} : {}}>
                          <div style={{paddingRight: '5px'}}>
                            <DetailsList
                              items={formRow}
                              columns={columnForm}
                              setKey="id"
                              selectionMode={SelectionMode.none}
                              layoutMode={DetailsListLayoutMode.justified}
                              selectionPreservedOnEmptyClick={false}
                              checkboxVisibility={0}
                              selection={selection}
                              onRenderItemColumn={(
                                item: any,
                                index?: any,
                                column?: IColumn,
                              ) => {
                                if (column && column.fieldName && column.fieldName === "projectDefinitionIds") {
                                  return (
                                    <Stack
                                      horizontal
                                      wrap
                                      // styles={stackStyles}
                                      tokens={{
                                        childrenGap: 5,
                                        padding: 0,
                                      }}
                                    >
                                      <Stack.Item grow={6}>
                                        <div className={`${classNames.fullWidth}`}>
                                          <Dropdown
                                            placeholder={t('project')}
                                            onChange={(event, item) => {
                                              handleDropdownChange(item, index)}
                                            }
                                            selectedKey={formRow?.[index]?.projectDefinitionId}
                                            multiSelect={false}
                                            options={
                                              projectOptions ? projectOptions : []
                                            }
                                          />
                                        </div>
                                      </Stack.Item>
                                    </Stack>
                                  )
                                } else if (column && column.fieldName && column.fieldName === 'action') {
                                  return (
                                    <Stack
                                      horizontal
                                      wrap
                                      // styles={stackStyles}
                                      tokens={{
                                        childrenGap: 5,
                                        padding: 0,
                                      }}
                                    >
                                      <div className={`${classNames.fullWidth}`}>
                                        <ProductTaxonomyField
                                          forCertifications={true}
                                          treeData={[]}
                                          selectItemId={formRow?.[index]?.productId ?? ""}
                                          onSelectItem={async (id: any) => {
                                            if (id) {
                                                const payload = {
                                                  title: null,
                                                  pbsProductStatusId: null,
                                                  address: null,
                                                  Coordinates:{
                                                    lat: "0.0",
                                                    lon: "0.0"
                                                  },
                                                  radius: null,
                                                  minLabourHours: "0",
                                                  maxLabourHours: "0"
                                                }
                                                const headers = {Cu: getContractingUnit(),Project: getProjectSequenceCode(formRow?.[index]?.projectDefinitionId)};
                                                const response = await client.post("PbsFilter/GetPbsForPmol",payload,{headers: headers})
                                                if (response?.status === 200) {
                                                  const items = getBreadcrumbItemFromData(response.data.result, id)
                                                  const updatedFormRow = [...formRow]; // Create a copy of the formRow array
                                                  updatedFormRow[index] = {
                                                    ...updatedFormRow[index],
                                                    productId: id, // Update the specific field with the selected value
                                                    productTitle: items[0].text
                                                  };
                                                  setFormRow(updatedFormRow)
                                                }
                                            }
                                          }}
                                          treeLevel={[]}
                                          onFilterTaxonomyNodeChanged={null}
                                          projectBreakdown={undefined}
                                          disabled={!formRow?.[index]?.projectDefinitionId}
                                          isModal={true}
                                          cu={getContractingUnit()}
                                          project={getProjectSequenceCode(formRow?.[index]?.projectDefinitionId)}
                                        />
                                      </div>
                                    </Stack>
                                  )
                                }
                                else if (column && column.fieldName && column.fieldName === 'productId') {
                                  return (
                                    <Input className={`${classNames.fullWidth}`} style={{border: '1px solid #000', color: '#000', padding: '4px 8px'}} readOnly value={formRow?.[index]?.productTitle || ""} />
                                  )
                                } 
                                // else if (column && column.fieldName && column.fieldName === 'checkbox') {
                                //   return (
                                //     <input
                                //       type="checkbox"
                                //       checked={selectedFormRow?.some((e: any)=> e.id === item.id)}
                                //       style={{width: 15, height: 15, marginTop: }}
                                      
                                //       // onChange={(e) => {
                                //       //   // Handle checkbox state change
                                //       //   item.isChecked = e.target.checked;
                                //       // }}
                                //       onClick={(e) => handleSelection(e, item)} // Prevent row selection on checkbox click
                                //     />
                                //   )
                                // }
                              }}
                              onRenderDetailsHeader={(headerProps, defaultRender) => {
                                if (defaultRender !== undefined) {
                                  return (
                                    <Sticky
                                      stickyPosition={StickyPositionType.Header}
                                      isScrollSynced={true}
                                      stickyBackgroundColor="transparent"
                                    >
                                      <div>{defaultRender(headerProps)}</div>
                                    </Sticky>
                                  );
                                } else {
                                  return _onRenderDetailsHeader(headerProps!!);
                                }
                                return <span></span>;
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={{
                        childrenGap: 5,
                        padding: 0,
                      }}
                    >
                      <div className={`${classNames.fullWidth}`}>
                        <Field name="certificationTaxonomyId">
                          {({ input, meta }) => (
                            <TaxonomyField
                              label={t('qualificationType')}
                              treeData={props.certificationTaxonomyList}
                              selectItemId={
                                values && values.certificationTaxonomyId
                                  ? values.certificationTaxonomyId
                                  : null
                              }
                              onSelectItem={(id: string) => {
                                if (id) {
                                  if (props.organisationSelectionDetails[0] && id === props.organisationSelectionDetails[0].certificationTaxonomyId) {
                                    input.onChange(null);
                                    setIsValid(false);
                                    setCertificate({
                                      ...certificate,
                                      certificationTaxonomyId: null,
                                    });
                                  } else {
                                    input.onChange(id);
                                    setIsValid(true);
                                    setCertificate({
                                      ...certificate,
                                      certificationTaxonomyId: id,
                                    });
                                  }
                                } else {
                                  input.onChange(null);
                                  setCertificate({
                                    ...certificate,
                                    certificationTaxonomyId: null,
                                  });
                                }
                              }}
                              treeLevel={
                                props.dropDowns.certificationTaxonomyLevel
                              }
                              onFilterTaxonomyNodeChanged={() => {
                              }}
                            // disabled={ false }
                            />
                          )}
                        </Field>
                        {!isValid &&
                          <Label style={{ marginTop: '-18px', color: '#a4262c', fontSize: '12px' }}>
                            {t('qualificationTypeCannotBeEmpty')}
                          </Label>
                        }
                      </div>
                    </Stack>
                    <div style={{ marginTop: !isValid ? '44px' : '28px'  }}>
                      <Stack
                        horizontal
                        wrap
                        styles={stackStyles}
                        tokens={stackTokens}
                      >
                        <Stack.Item grow={6} styles={stackItemStyles}>
                          <div className={`${classNames.halfWidth}`}>
                            <Field
                              name="certificationTitle"
                              component={TextFieldFF}
                              lable={t('certificationTitle')}
                              disabled={false}
                            />
                          </div>
                        </Stack.Item>
                      </Stack>
                      <Stack
                        horizontal
                        wrap
                        styles={stackStyles}
                        tokens={stackTokens}
                      >
                        <Stack.Item grow={5} styles={stackItemStyles}>
                          <div className={`${classNames.fullWidth}`}>
                            <div style={{ marginTop: '14px', display: 'flex', alignItems: 'flex-end', flexDirection: 'row' }}>
                              <Field
                                name="certificationUrl"
                                component={TextFieldFF}
                                lable={t('certificationUrl')}
                                disabled={false}
                                placeholder={t('certificationUrl')}
                                required={false}
                                readOnly={true}
                                className={`${classNames.middiumwidth}`}
                              />
                              <DragAndDropFileUpload multiple={false} getUploadedFile={(files) => handleFileDrop(files, form)} disabled={false} />
                            </div>
                          </div>
                        </Stack.Item>
                      </Stack>
                    </div>
                    <div style={{ marginTop: '40px' }}>
                      <Label>{t('validityPeriod')}</Label>
                      <Stack
                        horizontal
                        wrap
                        styles={stackStyles}
                        tokens={stackTokens}
                      >
                        <Stack.Item grow={4} styles={stackItemStyles}>
                          <div className={`${classNames.fullWidth}`}>
                            <Field
                              name="startDate"
                              component={DatePickerRequiredAdapter}
                              lable={t('startDate')}
                              parse={(value) =>
                                value && formatDateTimeToISOString(value)
                              }
                              format={(value) => (value ? new Date(value) : null)}
                              // errorMessage={
                              //   form.getState()?.errors?.startDate
                              // }
                            />
                            
                          </div>
                        </Stack.Item>

                        <Stack.Item grow={4} styles={stackItemStyles}>
                          <div className={`${classNames.fullWidth}`}>
                            <Field
                              name="endDate"
                              component={DatePickerRequiredAdapter}
                              lable={t('endDate')}
                              parse={(value) =>
                                value && formatDateTimeToISOString(value)
                              }
                              format={(value) => (value ? new Date(value) : null)}
                              minDate={new Date(form.getFieldState('startDate')?.value)}
                              errorMessage={
                                form.getState()?.errors?.endDate
                              }
                            />
                          </div>
                        </Stack.Item>
                        <Stack.Item grow={4} styles={stackItemStyles}>
                          <div className={`${classNames.halfWidth}`}>
                            <Field
                              name="countryId"
                              // value={values.countryId  || defaultCountryKey}
                              // selectedKey={values.countryId || defaultCountryKey}
                              lable="Valid in Country"
                              placeholder="Valid in Country"
                              component={DropDownAdaptater}
                              options={countryOptions}
                              validate={(value: any) => (value ? undefined : t('validation:required'))}
                            />
                          </div>
                        </Stack.Item>
                      </Stack>
                    </div>

                    <div className={'card-footer'} style={{ marginTop: '24px' }}>
                      <Separator />
                      <Stack
                        styles={stackStyles}
                        tokens={itemAlignmentsStackTokens}
                      >

                        <Stack.Item
                          align="end"
                          styles={stackFooterBarItemStyles}
                        >
                          <ActionButton
                            className={classNames.actionButton}
                            iconProps={saveIcon}
                            allowDisabledFocus
                            onClick={() => {
                              if (isValid) {
                                props.triggerCertificationSavedToTrue()
                                form.submit();
                              }
                              errors == null ? form.reset() : undefined;
                              setIsValid(false);
                            }}
                          >
                            <Label className={classNames.actionButton}>
                              {t('save')}
                            </Label>
                          </ActionButton>
                        </Stack.Item>
                      </Stack>
                    </div>
                  </form>
                );
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CertificationModal;
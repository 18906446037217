import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import moment from "moment/moment";
import {saveMyDayPlanningStateAttr, updateMyDayPlanningPmol} from "../../../reducers/myDayPlanningReducer";
import CustomWeek from "./customeWeek";
import EventWrapper from "./eventWrapper";
import CustomToolbar from "../../../shared/kanban/customToolbar";
import {handleCalendarEndTime, tooltipAccessor} from "../../../shared/util";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import {Calendar, momentLocalizer, View} from "react-big-calendar";
import {useDispatch, useSelector} from "react-redux";
import CustomEvent from "./customEvent";
import ResourceHeader from "./resourceHeader";
import {saveDayPlanningStateAttr} from "../../../reducers/dayPlanningReducer";
import * as TdDropBox from '../../../shared/dnd/dropBox';
import { AcceptType, ProjectPlanResourceType } from '../../../types/myDayPlanning';
import { useTranslation } from 'react-i18next';

interface Props {
    events: any;
    view: View;
    resources: any;
    updateEvent: (event: any, start: any, end: any, resourceId: any) => void;
}

function DndCalendar(props: Props) {
    const DragAndDropCalendar = withDragAndDrop(Calendar as any)
    const localize = momentLocalizer(moment)
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const myDpViewMode = useSelector((state: any) => state?.mydPlan?.myDpViewMode);
    const selectedDate = useSelector((state: any) => state?.mydPlan?.selectedDate);
    const selectedWeek = useSelector((state: any) => state?.mydPlan?.selectedWeek);
    const selectedMyDpPmol = useSelector((state: any) => state?.mydPlan?.selectedMyDpPmol);
    const userInfo = useSelector((state: any) => state.uprince.userInfo);
    const dndCalenderRef = useRef(null);
    const table : any = dndCalenderRef.current;
    const [scrollToTime, setScrollToTime] = React.useState<any>(null);

    const setStartTime = () => { // set focus at 5:00 work By Using this scroll
        if(myDpViewMode && myDpViewMode<=30){
            const top = myDpViewMode==7.5?'800':myDpViewMode==15?'400':'200';
            localStorage.setItem('lastScrollLeft', '0');
            localStorage.setItem('lastScrollTop', top);
        }
    }

    useEffect(()=> {
        if(props.view === 'day'){
            setStartTime();
        }        
    },[])

    useEffect(() =>{        
        if(props.view === 'day'){
            setStartTime();
            const sDate = new Date(selectedDate);
            const scrollTime = new Date(sDate.getFullYear(), sDate.getMonth(), sDate.getDate(), 5, 0, 0);
            setScrollToTime(scrollTime);
        }
    },[selectedDate,myDpViewMode])

    useEffect(() => {
        if (table) {
            const rbcTimeContent = table.querySelector('.rbc-time-content');
            if (rbcTimeContent) {
                function logScrollPositions() {
                    // Save both scroll positions to localStorage whenever scrolling occurs
                    localStorage.setItem('lastScrollLeft', rbcTimeContent.scrollLeft);
                    localStorage.setItem('lastScrollTop', rbcTimeContent.scrollTop);
                }

                rbcTimeContent.addEventListener('scroll', logScrollPositions);

                const lastScrollLeft = localStorage.getItem('lastScrollLeft');
                const lastScrollTop = localStorage.getItem('lastScrollTop');

                rbcTimeContent.scrollLeft = lastScrollLeft ? parseInt(lastScrollLeft) : 0;
                rbcTimeContent.scrollTop = lastScrollTop ? parseInt(lastScrollTop) : 800;
            }
        }
    }, [props]);

    const moveEvent = ({event, start, end, resourceId}: any) => {
        if (resourceId != 0) {
            let resource = props.resources.dayResources.find((item: any) => item.resourceId === resourceId);
            let data = {
                teamId: resource?.teamId,
                team: resource?.team,
                id: event?.id,
                executionDate: moment(event?.executionDate).format('YYYY/MM/DD'),
                executionStartTime: moment(start).format('HH:mm'),
                executionEndTime: handleCalendarEndTime(event?.executionDate, end)[0],
                projectSequenceCode: event?.projectSequenceCode,
                contractingUnit: event?.contractingUinit
            }

            Promise.all([
                props.updateEvent(event, start, handleCalendarEndTime(event?.executionDate, end)[1], resourceId)
            ]).then(() => {
                dispatch(updateMyDayPlanningPmol(data))
            });
        }
    };

    const resizeEvent = ({event, start, end}: any) => {
        let resource = props.resources.dayResources.find((item: any) => item.resourceId === event.resourceId);

        let data = {
            teamId: resource?.teamId,
            team: resource?.team,
            id: event?.id,
            executionDate: moment(event?.executionDate).format('YYYY/MM/DD'),
            executionStartTime: moment(start).format('HH:mm'),
            executionEndTime: moment(end).format('HH:mm'),
            projectSequenceCode: event?.projectSequenceCode,
            contractingUnit: event?.contractingUinit
        }

        Promise.all([
            props.updateEvent(event, start, end, event?.resourceId)
        ]).then(() => {
            dispatch(updateMyDayPlanningPmol(data))
        });
    };

    const selectSlotEvent = ({start, end, resourceId, slots, action}: any) => {
        if (resourceId != 0) {
            let resourceSelector
            if (props.resources.resources) {
                resourceSelector = props.resources.dayResources.find((item: any) => item.resourceId === resourceId);
            } else {
                resourceSelector = {
                    resourceId: 0,
                    resourceTitle: userInfo?.userName,
                    team: [{id: userInfo?.userId, name: userInfo?.userName}],
                    teamId: null,
                    teamTitle: null,
                }
            }
            // console.log({resourceSelector});

            dispatch(saveDayPlanningStateAttr("myCalendarSlotData", {start, end, resourceId, slots, action}));
            dispatch(saveDayPlanningStateAttr("resourceSelectorData", resourceSelector));
        }
    };

    const TimeGutterHeader = () => (
      <div style={{width:'100%',wordWrap:'break-word',fontSize:10,fontWeight:'bold'}}>
          <TdDropBox.DropBox
            item={{ resourceId: 0, resourceTitle: `${t('newPerson')}`, color: '#fafafa' }}
            day={selectedDate}
            type={[AcceptType.TEAM]}
          >
              {t('newPerson')}
          </TdDropBox.DropBox>
      </div>
    );

    const onSelectDateFromToolBar=(date:any)=>{
        dispatch(saveMyDayPlanningStateAttr('selectedDate', date))
    }

    return <div ref={dndCalenderRef} style={{height : "100%", width : "100%"}} className={`plan-board-calender-with-borders`}>
        <DragAndDropCalendar
            events={props.events ?? []}
            localizer={localize}
            // defaultView={Views.DAY}
            date={props.view === 'day' ? selectedDate : props.view === 'week' ? selectedWeek.toDate : moment().toDate()}
            onNavigate={(date) => {
                dispatch(saveMyDayPlanningStateAttr('selectedDate', date))
            }}
            onView={(view) => {
                dispatch(saveMyDayPlanningStateAttr('view', view));
            }}
            views={{day: true, week: CustomWeek}}
            view={props.view}
            step={myDpViewMode}
            timeslots={2}
            // defaultDate={moment().toDate()}
            resources={props.view === 'day' ? props.resources.dayResources : props.resources.resources}
            resourceIdAccessor={(dayResources: any) => props.view === 'day' ? dayResources.resourceId : props.resources.resources.resourceId}
            resourceTitleAccessor={(dayResources: any) => props.view === 'day' ? dayResources.resourceTitle : props.resources.resources.resourceTitle}
            components={{
                event: CustomEvent,
                eventWrapper: EventWrapper,
                resourceHeader: ResourceHeader,
                // toolbar: CustomToolbar,
                timeGutterHeader: TimeGutterHeader,
                toolbar: (props)=>CustomToolbar({...props,showAllButton:true,selectedDate,onSelectDateFromToolBar}),
            }}
            onEventDrop={moveEvent}
            onEventResize={resizeEvent}
            onSelectSlot={selectSlotEvent}
            selectable
            resizable={true}
            formats={{
                eventTimeRangeFormat: ({start, end}, culture: any, localizer: any) =>
                    localizer.format(start, 'HH:mm', culture) +
                    ' - ' +
                    localizer.format(end, 'HH:mm', culture),
                timeGutterFormat: (date, culture: any, localizer: any) =>
                    localizer.format(date, 'HH:mm', culture),
            }}
            dayLayoutAlgorithm="no-overlap"
            tooltipAccessor={tooltipAccessor}
            scrollToTime={scrollToTime}
        />
    </div>
}

export default DndCalendar;

import {
  ActionButton,
  DetailsListLayoutMode,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  PrimaryButton
} from '@fluentui/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../../theme';
import { RESOURCE_TYPE } from '../../../../types/purchaseOrder';
import { ActionButtonState } from '../../../../types/corporateProductCatalogue';
import { formatwithoutTime } from "../../../../shared/util";

const theme = getTheme();

const stackEditbarItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 45,
    alignItems: 'flex-end',
  },
};

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const removeIcon: IIconProps = {
  iconName: 'Delete',
  styles: iconButtonStyles,
};
const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };
const editIcon: IIconProps = { iconName: 'Edit', styles: iconButtonStyles };

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const LabourHistoryGrid = (props: {
  dataList: any[];
  title: string;
  link: string;
  id: string;
  columns: any[];
  isLoaded: boolean;
  isExpand: boolean;
  selection: any;
  setOpenPOResourceModal: () => void;
  resourceType?: RESOURCE_TYPE;
  unitPrice: string;
  readOnly?: boolean;
  openModel: () => void;
  editLabourHistory?: () => void;
  labourHistorySelection?: any;
  labourHistorySelectionDetails?: {};
  labourHistoryActionButtonState: ActionButtonState;
  isAbsence: boolean;
  deleteLabourHistory: () => void;
}) => {
  const { t } = useTranslation();

  const pmolColumns = [
    {
      key: 'column1',
      name: t('projectTitle'),
      fieldName: 'projectSequenceCode',
      minWidth: props.isExpand ? 200 : 100,
      maxWidth: props.isExpand ? 200 : 100,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('projectManager'),
      fieldName: 'projectManager',
      minWidth: props.isExpand ? 200 : 100,
      maxWidth: props.isExpand ? 200 : 100,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('date'),
      fieldName: 'executionDate',
      minWidth: props.isExpand ? 200 : 100,
      maxWidth: props.isExpand ? 200 : 100,
      isResizable: true,
      onRender: (item: any) => {
        return item.executionDate ? <span>{formatwithoutTime(item.executionDate)}</span> : <span>-</span>;
      }
    },
    {
      key: 'column4',
      name: t('startTime'),
      fieldName: 'executionStartTime',
      minWidth: props.isExpand ? 200 : 100,
      maxWidth: props.isExpand ? 200 : 100,
      isResizable: true,
    },
    {
      key: 'column5',
      name: t('endTime'),
      fieldName: 'executionEndTime',
      minWidth: props.isExpand ? 200 : 100,
      maxWidth: props.isExpand ? 200 : 100,
      isResizable: true,
    },
  ];

  const absenceColumns = [
    {
      key: 'column1',
      name: t('leaveType'),
      fieldName: 'leaveTypeText',
      minWidth: props.isExpand ? 200 : 100,
      maxWidth: props.isExpand ? 200 : 100,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('startDate'),
      fieldName: 'startDate',
      minWidth: props.isExpand ? 200 : 100,
      maxWidth: props.isExpand ? 200 : 100,
      isResizable: true,
      onRender: (item: any) => {
        return item.startDate ? <span>{formatwithoutTime(item.startDate)}</span> : <span>-</span>;
      }
    },
    {
      key: 'column3',
      name: t('endDate'),
      fieldName: 'endDate',
      minWidth: props.isExpand ? 100 : 100,
      maxWidth: props.isExpand ? 100 : 100,
      isResizable: true,
      onRender: (item: any) => {
        return item.endDate ? <span>{formatwithoutTime(item.endDate)}</span> : <span>-</span>;
      }
    },
    {
      key: 'column4',
      name: t('startTime'),
      fieldName: 'startTime',
      minWidth: props.isExpand ? 200 : 100,
      maxWidth: props.isExpand ? 200 : 100,
      isResizable: true,
    },
    {
      key: 'column5',
      name: t('endTime'),
      fieldName: 'endTime',
      minWidth: props.isExpand ? 200 : 100,
      maxWidth: props.isExpand ? 200 : 100,
      isResizable: true,
    },
  ];

  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
      minHeight: props.dataList && props.dataList.length > 1 ? 200 : 110,
    },
    pane: { maxWidth: 400 },
    textContent: { padding: '15px 10px' },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
  });

  useEffect(() => {
    changeColor();
  });
  const [currentPage, setCurrentPage] = React.useState(0);
  const itemsPerPage = 5
  // Function to paginate data based on current page
  const paginateData = (jsonData: any[], page: number, perPage: number) => {
    try {
      const start = page * perPage;
      const end = start + perPage;

      // Check if start is within bounds of the array
      if (start >= jsonData.length) {
        return [];
      } else {
        return jsonData.slice(start, Math.min(end, jsonData.length)); // Slice the array based on pagination
      }
    } catch (error) {
      console.error("Error handling pagination:", error);
      return []; // Return empty array on error
    }
  };
  const stackStyles: IStackStyles = { root: { padding: 0 } };
  const stackItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 40,
      alignItems: 'flex-start',
    },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 15,
    padding: 10,
  };

  const changeColor = () => {
    const cquantityClass = document.getElementsByClassName('mcomments');
    for (let i = 0; i < cquantityClass.length; i++) {
      const parentElement = cquantityClass[i].parentElement;
      if (parentElement) {
        parentElement.style.borderRight = '1px solid rgb(243, 242, 241)';
      }
    }

    const totalCost1Class = document.getElementsByClassName('mmou');
    for (let i = 0; i < totalCost1Class.length; i++) {
      const parentElement = totalCost1Class[i].parentElement;
      if (parentElement) {
        parentElement.style.borderRight = '1px solid rgb(243, 242, 241)';
      }
    }
  };
  const _onNextPage = () => {
    setCurrentPage(prev => prev + 1);
  };

  const _onPrevPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 0));
  };
  return (
    <div className="proj-detail-block" id={props.id}>
      <div className="marginTop">
        <div style={{ padding: 12 }}>
          <div className="ms-Grid-row">
            <Stack
              horizontal
              horizontalAlign="space-between"
              styles={stackStyles}
              tokens={stackTokens}
            >
              <Stack.Item styles={stackItemStyles}>
                <Label style={{ marginTop: 10 }}>{props.title}</Label>
              </Stack.Item>
              {!props.readOnly && props.isAbsence && (
                <Stack.Item styles={stackEditbarItemStyles}>
                  <ActionButton
                    className={classNames.actionButton}
                    disabled={props.labourHistoryActionButtonState.add}
                    iconProps={addIcon}
                    allowDisabledFocus
                    onClick={() => {
                      props.openModel();
                    }}
                  >
                    <Label className={classNames.actionButton}>{t('add')} </Label>
                  </ActionButton>
                  <ActionButton
                    iconProps={editIcon}
                    disabled={props.labourHistoryActionButtonState.edit}
                    allowDisabledFocus
                    onClick={() => {
                      props.openModel();
                    }}
                  >
                    <Label className={classNames.actionButton}> {t('edit')}</Label>
                  </ActionButton>
                  <ActionButton
                    iconProps={removeIcon}
                    disabled={props.labourHistoryActionButtonState.remove}
                    allowDisabledFocus
                    onClick={() => props.deleteLabourHistory()}
                  >
                    <Label className={classNames.actionButton}>
                      {' '}
                      {t('remove')}
                    </Label>
                  </ActionButton>
                </Stack.Item>
              )}
            </Stack>
          </div>
          <div className="ms-Grid-row marginTop">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
              <div className={classNames.wrapper}>
                <div className={'scrollablePane'}>
                  <ShimmeredDetailsList
                    items={paginateData(props.dataList, currentPage, itemsPerPage)}
                    columns={props.isAbsence ? absenceColumns : pmolColumns}
                    setKey="set"
                    selectionMode={
                      props.readOnly ? SelectionMode.none : SelectionMode.multiple
                    }
                    enableShimmer={props.isLoaded}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionPreservedOnEmptyClick={true}
                    checkboxVisibility={1}
                    // onRenderItemColumn={ (
                    //     item: any,
                    //     index?: number,
                    //     column?: IColumn,
                    // ) =>
                    //     POResourceColumn(
                    //         props.resourceType,
                    //         item,
                    //         index,
                    //         column,
                    //     )
                    // }
                    // onRowDidMount={()=>{
                    //   changeColor()
                    // }}
                    selection={props.labourHistorySelection}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="Row checkbox"
                  // onRenderDetailsHeader={(props)=>_onRenderDetailsHeader(props!!)}
                  />
                  
                  <Stack horizontal horizontalAlign="start">
                      <PrimaryButton onClick={_onPrevPage} disabled={currentPage === 0}>
                        Previous
                      </PrimaryButton>
                      <PrimaryButton
                      
                        style={{marginLeft:'12px'}}
                        onClick={_onNextPage}
                        disabled={(currentPage + 1) * itemsPerPage >= (props.dataList).length} // Disable if no more pages
                      >
                        Next
                      </PrimaryButton>
                    </Stack>
                  
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LabourHistoryGrid;

import React, {useEffect} from 'react';

import {walk} from '@nosferatu500/react-sortable-tree';
import {initTasks} from './helper';
import {ViewSwitcher} from './view-switcher';
import _ from 'lodash';
import {Text} from '@fluentui/react';
import {useDispatch, useSelector} from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import {useDebouncedCallback} from 'use-debounce';
import {
  addPOLabourTeam,
  addPOToolsPool,
  formatVpResourceBarData,
  saveVPStateAttr,
  updateHWTaxonomyDateDate,
  updateVpPersonDate,
} from '../../../reducers/visualPlanReducer';
import {getCPCTeams, getPersonIDArray, getTaxonomyIDArray} from '../utils';
import {useTranslation} from 'react-i18next';
import {RESOURCE_TYPE_IDS} from '../../../shared/util';
import {PURCHASE_REQUEST_TYPE_ID} from '../../../types/visualPlan';
import ProgressBar from '../../../shared/progressBar/progressBar';
import moment from 'moment';
import {ChartType, Task, ViewMode} from '../../../shared/ganttv2/types/public-types';

import {ExpandProvider} from '../../../hooks/expandContext';
import {Gantt} from '../../../shared/ganttv2';
import {ViewModeContext} from '../../projectVisualPlan/productTaxonomyListPane/component';

// export const ViewModeContextz = React.createContext({ onViewModeChange: (viewMode:ViewMode) => {}, onViewListChange: (isCheck:boolean) => {}, isCheck: true });

// Init
const ListPaneComponent = (props: { vpList: any[], isDataLoaded: boolean, vpBarList: any[], handleBarTaskItemClick: (id: any) => void }) => {
  const [view, setView] = React.useState<ViewMode>(ViewMode.Month);
  const [tasks, setTasks] = React.useState<Task[]>(initTasks());
  const [isChecked, setIsChecked] = React.useState(true);
  const [showGanttChart, setShowGanttChart] = React.useState(true);
  const [labourPerson, setLabourPerson] = React.useState<any>([]);
  // const [nextPath, setNextPath] = React.useState<any>([]);
  const [toolResource, setToolResource] = React.useState<any>([]);
  // const [treeData, setTreeData] = React.useState<any>([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();


  const reduxBarTasks: any[] = useSelector(
    (state: any) => state.vp.vpBarList,
  );

  const listTasks: any[] = useSelector(
    (state: any) => state.vp.vpList,
  );

  const interactBarTasks: any[] = useSelector(
    (state: any) => state.vp.vpInteractBarList,
  );

  const isLabourTeamAssigned: any[] = useSelector(
    (state: any) => state.vp.isLabourTeamAssigned,
  );

  const selectedResourceType: string = useSelector(
    (state: any) => state.vp.selectedResourceTypeOrg,
  );

  useEffect(() => {
    setTimeout(() =>{
      setView(view)
    },3000)
  },[])

  useEffect(() => {

    if (isLabourTeamAssigned) {
      console.log('isLabourTeamAssigned', isLabourTeamAssigned)
      // dispatch(readVPOrganizationTaxanomyData(new OrganisationSettingListPaneFilter()));
      // dispatch(readVPWareHouseTaxanomyData({ wareHouseId: null }));
    }
  }, [isLabourTeamAssigned]);


  const debounced = useDebouncedCallback(
    (value, nextPath) => {
      if (selectedResourceType === RESOURCE_TYPE_IDS.LABOURS) {
        dispatch(addPOLabourTeam({
          id: uuidv4(),
          poId: value.poId,
          personId: labourPerson.personId,
          cpcId: value.ccpcId,
          startDate: value.startDate,
          endDate: value.endDate,
        }));
        const payload = {
          personId: [
            {
              id: uuidv4(),
              poId: value.poId,
              personId: labourPerson.personId,
              personName: labourPerson.personName,
              cpcId: value.ccpcId,
              projectSequenceCode: value.project,
              parentId: labourPerson.id,
            },
          ],
          startDate: value.startDate,
          endDate: value.endDate,
        };
        dispatch(saveVPStateAttr('labourPersonId', labourPerson.id))
        dispatch(updateVpPersonDate(payload));
        dispatch(saveVPStateAttr('vpLTPpath', nextPath))

      } else {
        dispatch(addPOToolsPool({
          id: uuidv4(),
          poId: value.poId,
          assignedCPCId: toolResource.cpcId,
          requestedCPCId: value.ccpcId,
          wareHouseTaxonomyId: value.wareHouseTaxonomyId,
          resourceTypeId: value.resourceTypeId,
          startDate: value.startDate,
          endDate: value.endDate,
          project: value.project,
        }));

        const payload = {
          taxonomyId: [{
            id: uuidv4(),
            poId: value.poId,
            projectSequenceCode: value.project,
            cpcId: toolResource.cpcId,
            parentId: toolResource.id,
            startDate: value.startDate,
            endDate: value.endDate,
          }],
          startDate: value.startDate,
          endDate: value.endDate,
        };
        dispatch(updateHWTaxonomyDateDate(payload));
      }
    },
    // delay in ms
    2000,
  );

  let columnWidth = 60;
  if (view === ViewMode.Day) {
    columnWidth = 60;
  } else if (view === ViewMode.Month) {
      columnWidth = 300;
  } else if (view === ViewMode.Week) {
      columnWidth = 250;
  } else if (view === ViewMode.QuaterYear) {
      columnWidth = 350;
  }

  const handleTaskChange = (task: any) => {
    const newTasks = interactBarTasks.map(t => (t.id === task.id ? task : t));
    dispatch(saveVPStateAttr('vpInteractBarList', newTasks));
    const cpcLabourTeams = getCPCTeams(task.teams, task.cpcId);
    const resources = getCPCTeams(task.toolsPool, task.cpcId);
    if (cpcLabourTeams.length > 0 && task.cpcId && task.resourceType === 'Labour') {
      getPersonIDArray(cpcLabourTeams);
      const payload = {
        personId: cpcLabourTeams,
        startDate: moment(task.start).format('YYYY-MM-DD') + 'T00:00:00.000Z',
        endDate: moment(task.end).subtract(1, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z',
      };
      dispatch(updateVpPersonDate(payload));
    }

    if (resources.length > 0 && task.cpcId && task.resourceType === 'Tools') {
      getTaxonomyIDArray(resources);
      const payload = {
        personId: resources,
        startDate: moment(task.start).format('YYYY-MM-DD') + 'T00:00:00.000Z',
        endDate: moment(task.end).subtract(1, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z',
      };
      dispatch(updateHWTaxonomyDateDate(payload));
    }
    // setTasks(newTasks);
  };

  const handleTaskDelete = (task: Task) => {
    const conf = window.confirm('Are you sure about ' + task.name + ' ?');
    if (conf) {
      setTasks(tasks.filter((tsk: any) => tsk.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task: Task) => {
    // setTasks(tasks.map(tsk => (tsk.id === task.id ? task : t)));
    // console.log('On progress change Id:' + task.id);
  };

  const handleDblClick = (task: Task) => {
    dispatch(saveVPStateAttr('showPODocumentPane', true));
    dispatch(saveVPStateAttr('selectedPO', task.poId));
    // props.handleBarTaskItemClick(task.poId);
  };

  const handleSelect = (task: Task, isSelected: boolean) => {
    // props.handleBarTaskItemClick(task.poId);
  };

  const handleExpanderClick = (task: Task) => {
    // setTasks(tasks.map(tsk => (tsk.id === task.id ? task : t)));
  };

  const stringToArr = (string1: any) => {
    if (string1) {
      const x = string1.trim().split(',');
      x.shift();
      return x;
    }
    return [];
  };

  const getNodeKey = ({ treeIndex }: any) => treeIndex;

  const sumNodeValues = (treeData: any) => {
    let indexss = '';
    const callback = ({ node }: any) => {
        indexss += ',' + node.id;
      }
    ;

    walk({
      treeData,
      getNodeKey,
      callback,
      ignoreCollapsed: true,
    });


    return indexss;
  };

  const onVisibilityChanged = (data: any) => {
    const treeTotal1 = sumNodeValues(data);
    if (stringToArr(treeTotal1).length <= interactBarTasks.length) {
      // const newTasks = reduxBarTasks.filter(f => stringToArr(treeTotal1).some((item:any) => item === f.id));
      const newTasks = interactBarTasks.filter(item => stringToArr(treeTotal1).includes(item.id));
      dispatch(saveVPStateAttr('vpInteractBarList', newTasks));
    } else {
      const newTasks = reduxBarTasks.filter(item => stringToArr(treeTotal1).includes(item.id));
      dispatch(saveVPStateAttr('vpInteractBarList', newTasks));
    }
  };

  const onViewModeChange = (viewMode:ViewMode) => {
    setView(viewMode);
  };

  const onvisibilityToggled = (data:any) => {
  }

  return (
    <div>
       <ViewModeContext.Provider value={ {
                onViewModeChange: (viewMode:ViewMode) => {onViewModeChange(viewMode);},
                onViewListChange: (isCheck:boolean) => setIsChecked(isCheck),
                isCheck: isChecked,
                onShowGanttChartChange: (showGanttChart: boolean) => {},
                showGanttChart: showGanttChart
            } }>
              <ExpandProvider>
      <ViewSwitcher

      />
      {!props.isDataLoaded &&
      <ProgressBar show={true}/>
      }
      {props.isDataLoaded && _.isEmpty(interactBarTasks) &&
      <Text style={{ marginLeft: 20 }}>{t('noData')}</Text>
      }
      {props.isDataLoaded && !_.isEmpty(listTasks) &&
      <div id="poChart">
        <Gantt
                tasks={(props.vpList && _.isArray(props.vpList) && !_.isEmpty(props.vpList) && props.isDataLoaded) && !_.isEmpty(interactBarTasks) ? interactBarTasks : tasks}
                // tasks={ tasks }
                listTasks={(props.vpList && _.isArray(props.vpList) && !_.isEmpty(props.vpList) && props.isDataLoaded) ? listTasks : tasks}
                viewMode={view}
                onDateChange={handleTaskChange}
                onDelete={handleTaskDelete}
                onProgressChange={handleProgressChange}
                onDoubleClick={handleDblClick}
                onSelect={handleSelect}
                onExpanderClick={handleExpanderClick}
                listCellWidth={isChecked ? '155px' : ''}
                columnWidth={columnWidth}
                onVisibilityChanged={onVisibilityChanged}
                onVisibilityToggle={onvisibilityToggled}
                ganttHeight={560}
                isInitialCollaps={true}
                canDrag={({ node, path, treeIndex }: any) => {
                  return false;
                } }
                searchQuery={''}
                searchMethod={() => {
                } }
                searchFocusOffset={0}
                searchFinishCallback={(matches) => {
                } }
                onlyExpandSearchedNodes={true}
                rtl={false}

                canDrop={({ node, nextParent, nextPath }: any) => {
                  let canDrop = false;
                  if (nextParent?.requestTypeId === PURCHASE_REQUEST_TYPE_ID) {
                    canDrop = true;
                    if (node.personId && nextParent.resourceType === 'Labour') {
                      debounced(nextParent, nextPath);
                    } else if (node.hasOwnProperty('whTaxonomyLevelId') && nextParent.resourceType === 'Tools') {
                      debounced(nextParent, nextPath);
                    } else {
                      canDrop = false;
                    }
                  } else {
                    canDrop = false;
                  }
                  return canDrop;
                } }
                onMoveNode={(path, node) => {
                  console.log("NNNNNNN", path);
                  if (node.personId) {
                    setLabourPerson(node);
                  } else if (node.hasOwnProperty('whTaxonomyLevelId')) {
                    setToolResource(node);
                  }
                } }
                chartType={ChartType.PO}
                onTreeDataChange={(treeData) => {
                  dispatch(saveVPStateAttr('vpBarList', [...formatVpResourceBarData(treeData)[0]]));
                  dispatch(saveVPStateAttr('vpInteractBarList', [...formatVpResourceBarData(treeData)[1]]));
                } } selectedScreen={''}        />
      </div>}
      </ExpandProvider>
      </ViewModeContext.Provider>
    </div>
  );
};

export default ListPaneComponent;

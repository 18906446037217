import * as React from 'react';
import {
  ContextualMenu,
  FontWeights, getTheme,
  IconButton,
  IDragOptions,
  IIconProps, IStackItemStyles, IStackStyles, IStackTokens, Label, Stack,
  mergeStyleSets,
  Modal, TextField, PrimaryButton,
} from '@fluentui/react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { uPrinceTheme } from '../../../../theme';
const theme = getTheme();
import i18n from '../../../../i18n';
// import CustomDropdown from '../../../shared/customDropdown/customDropdown';
import client from '../../../api';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ProjectBreakdown } from '../../../types/projectBreakdownStructure';
import { saveCbcToPbs } from '../../../reducers/projectBreakdownReducer';
// import { readCIAWById, sendCIAW } from '../../../reducers/ciawReducer';
import { store } from '../../../../index';
import ClearableDropdown from '../../../shared/clearableDropdown/clearableDropdown';

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
  },
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '32vw',
    minHeight: screen.width > 1280 ? '42vh' : '60vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const modalStyles = {
  main: {
    maxWidth: '1200px', // Set the maximum width of the modal
    width: '200%',      // You can also use percentage values
  },
};
const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
interface Item {
  id: string;
  lotId: string;
  companyId: string;
  title: string;
  articleNo: string;
  quantity: string;
  unit: string;
  pbsId?: string;
  consumedQuantity:string;
}
const CbcModal = (props: { isOpen: boolean, openModal: any, contractor: any, projectBreakdown: ProjectBreakdown, onSaveSuccess: any, formData: any, pbsCbcList: any }) => {
  const dispatch = useDispatch();
  const [isDraggable] = useBoolean(false);
  const titleId = useId('title');
  const [cbc, setCbc]: any = React.useState<any>(null);
  const [mou, setMou]: any = React.useState<any>();
  const [cbcitem, setCbcitem]: any = React.useState<any>();
  const [quantityValidationMsg, setQuantityValidationMsg]: any = React.useState<any>("");
  const [quantity, setQuantity]: any = React.useState<any>('0');
  const [cbcQuantity, setCbcQuantity]: any = React.useState<any>('0');
  const [disableSave, setDisableSave] = useState(true);
  const [focus, setFocus] = useState(1);
  const [data, setData] = useState<Item[]>([]);
  // Your data array

  const cbcContractors = store.getState().projectBreakdown.cbcContractor ? store.getState().projectBreakdown.cbcContractor : [];
  useEffect(() => {
    if (props?.formData?.id) {
      const filteredData = props?.pbsCbcList.filter((item: any) => item.id === props?.formData?.id);
      setCbc({ key: filteredData[0].lotId, text: filteredData[0].lotId });
      setData(filteredData)
    }  else {
      if(props?.contractor){
        const filteredData = props?.pbsCbcList.filter((item: any) => item.lotId === props?.contractor.key);
        setData(filteredData)
      }else{
        setCbc(null);
        setData([])
      }
      
    }
   
  }, [props?.formData, props?.projectBreakdown?.id]);

  const onModalClose = () => {
    props.openModal();
    // props.projectBreakdown.pbsLotId = props.contractor.key
  }

  useEffect(() => {
    if (cbc && quantity) {
      setDisableSave(false)
    } else {
      setDisableSave(true)
    }
  }, [cbc, quantity]);

  const saveForm = async () => {
    const filteredData = updatedData
      .filter(item => item.pbsId)
      .map(item => ({
        id: props?.formData?.id !== null && props?.formData?.id !== undefined && props?.formData?.id !== '' ? props?.formData?.id : null,
        articleNo: item.articleNo,
        quantity: item.quantity,
        pbsId: item.pbsId,
        lotId: item.lotId,
        consumedQuantity:item.consumedQuantity
      }));
    dispatch(saveCbcToPbs(filteredData))
  }

  const onSaveCbc = () => {
    Promise.all([
      saveForm()
    ]).then(() => {
      setCbc(null);
      // setQuantity('0');
      setMou('')
      setCbcitem('')
      props?.onSaveSuccess()
      onModalClose();
    });
  }
  const onSaveAndNewCbc = () => {
    setFocus(0);
    Promise.all([
      saveForm()
    ]).then(() => {
      props?.onSaveSuccess()
      setCbc(null);
      setData([])
      setMou('')
      setCbcitem('')
      setFocus(1);
    });
  }

  useEffect(() => {
    if (props.contractor !== null) {
      setCbc(props.contractor)
      getProductByName(props?.contractor?.key)
      setDisableSave(false)
    } else {
      setData([])
      setCbc(null)
      setDisableSave(true)
    }
  }, [props.contractor])

  const getProductByName = async (cbc: string) => {
    const filter = { title: null, lotId: cbc };
    const response = await client.post('/Contractor/GetAwardedContractorLotData', filter);
    
    const filtercbc = response.data.result.map((item:any) => ({
      ...item,
      quantity: ""
    }));
   
      if (cbc !== null && cbc !== '' && cbc !== undefined) {
        const newDataTitles = props?.pbsCbcList.map((item: any) => item.title);

        const filteredFilterCbc = filtercbc.filter((item: any) => {
          return !newDataTitles.includes(item.title);
        });
        setData(filteredFilterCbc)
      } else {
        setData([])
      }
    
  };
  
  const cbcdata: Item[] = data
  const updatedData: Item[] = data
  return (
    <>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isOpen}
        onDismiss={() => {
          onModalClose();
        }}
        scrollableContentClassName="body"
        isBlocking={true}
        dragOptions={isDraggable ? dragOptions : undefined}
        styles={modalStyles}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>{i18n.t("cbc") as string}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              onModalClose();
            }}
          />
        </div>
        <div className={contentStyles.body}>
          <div>
            <div>
              <Stack horizontal wrap styles={stackStyles} tokens={stackTokens}>
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div style={{ width: '100%' }}>
                    <Label style={{ marginTop: 10 }}>{String(i18n.t('contractor'))}</Label>
                    {focus &&
                      <ClearableDropdown
                        required={false}
                        placeholder={i18n.t('contractor')}
                        label={''}
                        options={cbcContractors}
                        onChange={(event, value) => {
                          if (value) {
                            console.log({ value });
                            setCbc(value)
                            getProductByName(value?.key)
                            props.projectBreakdown.pbsLotId = value?.key
                          } else {
                            setCbc(null)
                            props.projectBreakdown.pbsLotId = null
                            setData([])
                          }
                        }}
                        disabled={props.contractor?true:false}
                        selectedKey={props.contractor? props.contractor.key :cbc ? cbc.key: ''}
                      />

                    }
                  </div>

                </Stack.Item>
              </Stack>
            </div>
            <div style={{ margin: '9px' }}>
              <div className="ms-Grid-row" style={{ display: 'flex', marginBottom: '12px' }}>
                <div className="ms-Grid-col ms-sm12 ms-md9 ms-lg8">
                  <Label style={{ marginTop: 10 }}>{i18n.t('CBC Items') as string}</Label>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg1">
                  <Label style={{ marginTop: 10 }}>{i18n.t('CBC Quantity') as string}</Label>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg1">
                  <Label style={{ marginTop: 10 }}>{i18n.t('Planned Quantity') as string}</Label>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg1">
                  <Label style={{ marginTop: 10 }}>{i18n.t('Consumed Quantity') as string}</Label>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg1">
                  <Label style={{ marginTop: 10 }}>{i18n.t('mou') as string}</Label>
                </div>
              </div>
              {cbcdata.map(item => (
                <div className="ms-Grid-row" style={{ display: 'flex', marginBottom: '12px' }}>
                  <div className="ms-Grid-col ms-sm12 ms-md8 ms-lg8">
                  <TextField
                        style={{ color: 'black' }}
                        value={item.title}
                        errorMessage={quantityValidationMsg}
                        type={'text'}
                        disabled={true}
                      />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg1">
                  <TextField
                        style={{ color: 'black' }}
                        value={quantity}
                        errorMessage={quantityValidationMsg}
                        type={'number'}
                        step={1}
                        min={0}
                        disabled={true}
                      />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg1">
                  <TextField
                        style={{ color: 'black' }}
                        value={item.quantity
                          ? item.quantity
                          : ''
                        }
                        errorMessage={quantityValidationMsg}
                        type={'number'}
                        onChange={(event, newValue) => {
                          if (newValue === null || newValue === undefined) return; 
                          const updatedItem = { ...item };
                          updatedItem.quantity = newValue;
                          if (item.quantity !== newValue) {
                            updatedItem.pbsId = props?.projectBreakdown.id ?? undefined;
                          }
                          const updatedData = cbcdata.map((dataItem) =>
                            dataItem.id === updatedItem.id ? updatedItem : dataItem
                          ); 
                          setData(updatedData); 
                        }}
                      />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg1">
                  <TextField
                        style={{ color: 'black' }}
                        value={item.consumedQuantity
                          ? item.consumedQuantity
                          : ''
                        }
                        errorMessage={quantityValidationMsg}
                        type={'number'}
                        onChange={(event, newValue) => {
                          if (newValue === null || newValue === undefined) return; 
                          const updatedItem = { ...item };
                          updatedItem.consumedQuantity = newValue;
                          if (item.consumedQuantity !== newValue) {
                            updatedItem.pbsId = props?.projectBreakdown.id ?? undefined;
                          }
                          const updatedData = cbcdata.map((dataItem) =>
                            dataItem.id === updatedItem.id ? updatedItem : dataItem
                          ); 
                          setData(updatedData); 
                        }}
                      />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg1">
                  <TextField
                        style={{ color: 'black' }}
                        value={item.unit
                          ? item.unit
                          : ''
                        }
                        errorMessage={quantityValidationMsg}
                        type={'text'}
                        disabled={true}
                      />
                  </div>
                </div>
              ))}
            </div>
           
          </div>
        </div>
        <div className={contentStyles.footer}>
          <PrimaryButton
            iconProps={addIconWhite}
            text={i18n.t('save')}
            disabled={disableSave}
            style={{ marginTop: 20, marginBottom: 30, marginRight: 20 }}
            onClick={() => {
              onSaveCbc()
            }}
          />

          <PrimaryButton
            iconProps={addIconWhite}
            text={i18n.t('saveAndNew')}
            disabled={disableSave}
            style={{ marginTop: 20, marginBottom: 30 }}
            onClick={() => {
              onSaveAndNewCbc();
            }}
          />
        </div>
      </Modal>
    </>
  );

};

export default CbcModal

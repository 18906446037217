import React from 'react'
import { ActionButton, DetailsList, DetailsListLayoutMode, getTheme, IColumn, IIconProps, IObjectWithKey, ISelection, IStackItemStyles, IStackStyles, IStackTokens, Label, mergeStyleSets, SelectionMode, Stack } from '@fluentui/react'
import { uPrinceTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';

interface GroupByDataGridProps {
  columns: IColumn[],
  data: any;
  onClickAdd: any;
  onClickEdit: any;
  onClickRemove: any;
  assetItemSelection: ISelection<IObjectWithKey> | undefined;
  selected: any
  // modalTitle: any;
}

const theme = getTheme();

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};
const stackStyles: IStackStyles = { root: { padding: 0 } };
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 40,
    alignItems: 'flex-start',
  },
};
const stackEditbarItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 45,
    alignItems: 'flex-end',
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 15,
  padding: 10,
};

const GroupByDataGridForAssetRegisters = ({
  columns, 
  data, 
  onClickAdd, 
  onClickEdit, 
  onClickRemove,
  assetItemSelection,
  selected
}: GroupByDataGridProps) => {
  const {t} = useTranslation()
  const removeIcon: IIconProps = {
    iconName: 'Delete',
    styles: iconButtonStyles,
  };
  const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };
  const editIcon: IIconProps = { iconName: 'Edit', styles: iconButtonStyles };
  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      minHeight: (data && data?.length > 1) ? 200 : 110,
      position: 'relative',
      maxHeight: 'inherit',
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
  });

  // Transform data into grouped format
  const transformData = (data: any) => {
    let items: any[] = [];
    let groups: any[] = [];
    let groupStartIndex = 0;

    data.forEach((register: any) => {
        // Define group with register item as the header
        const group = {
            key: register?.id,
            name: register?.name,
            startIndex: groupStartIndex,
            count: register?.assetItems?.length
        };
        groups.push(group);

        // Add asset items
        register?.assetItems?.forEach((asset: any) => {
            items.push({
                key: asset?.id,
                type: asset?.type,
                kind: asset?.kind,
                fluvius: asset?.fluvius,
                measuring: asset?.measuring,
                yard: asset?.yard,
                progressStatement: asset?.progressStatement,
                counteroffer: asset?.counteroffer,
                invoice: asset?.invoice,
                externalId: asset?.externalId,
                registerId: asset?.registerId,
                register: asset?.register,
                id: asset?.id,
                number: asset?.number,
                name: asset?.name,
                title: asset?.title,
                statusId: asset?.statusId,
                status: asset?.status,
                stateId: asset?.stateId,
                state: asset?.state,
                projectSequenceId: asset?.projectSequenceId,
                created: asset?.created,
                createdBy: asset?.createdBy,
                modified: asset?.modified,
                group: register?.id // Link item to its group
            });
        });
        groupStartIndex += register?.assetItems?.length;
    });
    return { items, groups };
  };

  const { items, groups } = transformData(data);

  return (
    <>
      <div className="ms-Grid-row">
        <div className="sub-header-title">
          <Stack
            horizontal
            horizontalAlign="space-between"
            styles={stackStyles}
            tokens={stackTokens}
          > 
            <Stack.Item styles={stackItemStyles}>
              <Label style={{ marginTop: 10 }}>4.1 {t('assetRegisters')}</Label>
            </Stack.Item>
            <Stack.Item styles={stackEditbarItemStyles}>
              <ActionButton
                className={classNames.actionButton}
                iconProps={addIcon}
                allowDisabledFocus
                onClick={() => onClickAdd()}
              >
                <Label className={classNames.actionButton}>{t('add')} </Label>
              </ActionButton>
              <ActionButton
                iconProps={editIcon}
                allowDisabledFocus
                onClick={() => onClickEdit()}
                disabled={selected?.length === 0 ? true : false}
              >
                <Label className={classNames.actionButton}> {t('edit')}</Label>
              </ActionButton>
              <ActionButton
                iconProps={removeIcon}
                allowDisabledFocus
                onClick={() => onClickRemove()}
                disabled={selected?.length === 0 ? true : false}
              >
                <Label className={classNames.actionButton}>
                  {t('remove')}
                </Label>
              </ActionButton>
            </Stack.Item>
          </Stack>
        </div>

        <div className={classNames.wrapper}>
          <DetailsList 
            items={items}
            columns={columns}
            groups={groups}
            setKey="set"
            selection={assetItemSelection}
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.single}
            checkboxVisibility={1}
            styles={{
              root: { 
                height: '300px',
                overflowY: 'auto'
              }
            }}
          />
        </div>
      </div>
    </>
  )
}

export default GroupByDataGridForAssetRegisters
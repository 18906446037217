import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionButton,
  DetailsListLayoutMode,
  FontIcon,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  Label,
  Link,
  mergeStyles,
  mergeStyleSets,
  Separator,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../../theme';
import TextEditor from '../../../shared/textEditor/textEditor';
import { UPLOAD_PMOL_EXTRA_WORK_FILE_EP } from '../../../shared/endpoints';
import { messageService } from '../../../services/messageService';
import {
  ExtraWork as ExtraWorkTypes,
  ProjectMolecule,
  ProjectMoleculeValidationMessage,
} from '../../../types/projectMolecule';
import ExtraWorkResources from '../resources/extraWorkResources/extraWorkResources';
import CustomFileUploader from '../../../shared/fileUploader/customFileUploader';

// Styles definition
const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
  },
};

// Tokens definition

const stackFooterBarItemStyles: IStackItemStyles = {
  root: {
    height: 45,
  },
};
const theme = getTheme();
const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  wrapper: {
    //height: '100vh',
    minHeight: 130,
    position: 'relative',
    maxHeight: 'inherit',
  },
  fullWidth: {
    width: '100%',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  bigScreenTree: {
    marginBottom: 30,
    height: '30vh',
  },
  smallScreenTree: {
    marginBottom: 30,
    height: '56vh',
  },
  textEditor: {
    height: window.innerWidth <= 1280 ? '50vh' : '25vh',
  },
});

const iconClass = mergeStyles({
  fontSize: 20,
  height: 20,
  width: 20,
  margin: '0 5px',
});

const ExtraWork = (props: {
  isEdit: boolean;
  dropDownOptions: any;
  extraWork: ExtraWorkTypes;
  formData: ProjectMolecule;
  saveFormData: () => void;
  handleFormChange: (formData: any, save: boolean) => void;
  validationMessages: ProjectMoleculeValidationMessage;
  readOnly: boolean;
  readExtraWork: boolean;
  renameSuccess?: () => void;
}) => {
  const { t } = useTranslation();
  const [extraWorkImages, setExtraWorkImages] = useState<any>([]);

  const columns = [
    {
      key: 'column1',
      name: t('title'),
      fieldName: 'title',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('url'),
      fieldName: 'link',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        return (
          <div>
            {/*MusicNote*/}
            <Link
              style={{ borderBottom: 'none', cursor: 'pointer' }}
              key={item.title}
              href={item.link ? item.link : ''}
              target={'_blank'}
            >
              <FontIcon iconName="BoxPlaySolid" className={iconClass}/>
              {/*<Label style={{ fontSize: '0.8rem', cursor: 'pointer' }}>*/}
              {/*{' '}*/}
              {/*Audio Link{' '}*/}
              {/*</Label>*/}
            </Link>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (props.formData && props.formData.id) {
      props.extraWork.pmolId = props.formData.id;
    }
  }, [props.formData]);

  useEffect(() => {
    if (props.extraWork && props.extraWork.imageList) {
      let newImage: any = [];
      props.extraWork.imageList.forEach((item: any) => {
        newImage.push({
          id:item.id,
          image: item.link,
          type: item.type,
          isExtrawork: true,
          isApproved: item.isApproved
        });
      });
      setExtraWorkImages(newImage);
    }
  }, [props.extraWork]);

  const onImageUpload = (image: any) => {
    let currentImageList: [] = extraWorkImages;
    let newImage: [] = image;
    currentImageList.concat(newImage);
    setExtraWorkImages(currentImageList);
    let imagedetails: any = {
      title: null,
      link: image,
      type: 1,
      pmolExtraWorkId: props.extraWork.id,
    };
    props.extraWork.extraWorkFiles.push(imagedetails);
  };
  const saveFormData = () => {
    messageService.sendMessage({
      saveExtraWorkData: true,
    });
  };

  return (
    <div className="document-pane-card">
      <div className={'card-header'}>
        <Link href="#" id="extra-work">
          <Label>5. {t('extraWork')} </Label>
        </Link>
      </div>
      <div className={'card-body'}>
        <div className="proj-detail-content inner">
          <div className="proj-detail-block" id="13">
            <div id={'extra-work-id-4-1'}>
              <div className="marginTop">
                <div className="sub-header-title">
                  <Label>5.1 {t('description')}</Label>
                </div>

                <div className={`${classNames.fullWidth}`}>
                  <Label>{t('descriptionofExtraWork')}</Label>
                  <TextEditor
                    value={
                      props.extraWork && props.extraWork.description
                        ? props.extraWork.description
                        : ''
                    }
                    onChange={(value) => {
                      if (value) {
                        props.extraWork.description = value;
                      } else {
                        props.extraWork.description = null;
                      }
                      // props.handleFormChange(
                      //   props.formData,
                      //   false
                      // );
                    }}
                  />
                </div>
              </div>

              <div className="marginTop">
                <CustomFileUploader
                  pmolId ={props?.formData?.projectMoleculeId!!}
                  label={t('pictures')}
                  uploadedImages={extraWorkImages}
                  uploadUrl={UPLOAD_PMOL_EXTRA_WORK_FILE_EP}
                  onSaveSuccess={(res: any) => {
                    onImageUpload(res);
                  } }
                  disable={props.readOnly}
                  productId={props.formData.productId} checkedItems={[]}
                  showCheckbox={true}
                  showDelete={true}
                  showCopyToPbs={true}
                  showRename={true}
                  sectionHeading={'EXTRAWORK'}
                  renameSuccess={() =>{
                    if(props.renameSuccess){
                      props.renameSuccess();
                    }            
                  }}
                  />
              </div>

              <div className="marginTop">
                <div className={`${classNames.fullWidth}`}>
                  <div className={classNames.wrapper}>
                    <div className={'scrollablePane'}>
                      <Label>{t('audioRecording')}</Label>
                      <ShimmeredDetailsList
                        items={
                          props.extraWork && props.extraWork.audioList
                            ? props.extraWork.audioList
                            : []
                        }
                        columns={columns}
                        setKey="set"
                        enableShimmer={false}
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionPreservedOnEmptyClick={true}
                        checkboxVisibility={1}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="Row checkbox"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="marginTop">
            <div className={`${classNames.fullWidth}`}>
              <ExtraWorkResources
                formData={props.formData}
                readOnly={props.readOnly}
                readExtraWork={props.readExtraWork}
              />
            </div>
          </div>

        </div>
      </div>

      <div className={'card-footer'}>
        <Separator/>
        <Stack styles={stackStyles}>
          <Stack.Item align="end" styles={stackFooterBarItemStyles}>
            <ActionButton
              className={classNames.actionButton}
              iconProps={saveIcon}
              allowDisabledFocus
              onClick={() => {
                saveFormData();
              }}
              disabled={props.readOnly}
            >
              <Label className={classNames.actionButton}>{t('save')} </Label>
            </ActionButton>
          </Stack.Item>
        </Stack>
      </div>
    </div>
  );
};

export default ExtraWork;

import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionButton,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Separator,
  Stack,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../../theme';
import TextEditor from '../../../shared/textEditor/textEditor';
import CustomFileUploader from '../../../shared/fileUploader/customFileUploader';
import { Journal, JournalImage } from '../../../types/projectMolecule';

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 0,
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const stackFooterBarItemStyles: IStackItemStyles = {
  root: {
    height: 45,
  },
};
const theme = getTheme();
const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  fullWidth: {
    width: '100%',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  bigScreenTree: {
    marginBottom: 30,
    height: '30vh',
  },
  smallScreenTree: {
    marginBottom: 30,
    height: '56vh',
  },
  textEditor: {
    height: window.innerWidth <= 1280 ? '50vh' : '25vh',
  },
});

const ProjectMoleculerJournal = (props: {
  PMOLSaveJournal: () => void;
  setJournal: (feild: any, value: any) => void;
  setJournalImage: (type: string, response: any) => void;
  journal: Journal
  readOnly: boolean
  productId:any
  renameSuccess?: () => void;
  pmolId?: string;
}) => {
  const { t } = useTranslation();

  const getImages = (images: JournalImage[], type: string) => {
    if (images) {
      let typeImages = images.filter(image => image.type === type);
      let formattedImages = typeImages.map((item: any) => {
        return {
          image: item.link,
          id: item.id,
          type: item.type,
          isLast: true,
          isJournal: true,
          isApproved: item.isApproved
        };
      });
      return formattedImages;
    } else {
      return [];
    }
  };

  return (
    <div className="proj-detail-content inner">
      <div className="marginTop">

        <div className={`${classNames.fullWidth}`}>
          <Label>{t('whathaveyoudonetoday')}</Label>
          <TextEditor
            value={props.journal && props.journal.doneWork ? props.journal.doneWork : ''}
            onChange={(value) => {
              if (value) {
                // props.formData.comments = value;
                props.setJournal('doneWork', value);
              } else {
                props.setJournal('doneWork', null);
              }
            }}
          />
        </div>
      </div>

      <div className="marginTop">
        <CustomFileUploader
          pmolId={props?.pmolId}
          uploadedImages={getImages(props.journal ? props.journal.pictureList : [], '1')}
          label={t('pictures')}
          uploadUrl={'PmolJournal/UploadJournalDocuments'}
          onSaveSuccess={(response: any) => props.setJournalImage('1', response)}
          disable={props.readOnly}
          productId={props.productId} checkedItems={[]}
          showCheckbox={true}
          showDelete={true}
          showCopyToPbs={true}
          showRename={true}
          sectionHeading={'JOURNALWORK'}
          renameSuccess={() =>{
            if(props.renameSuccess){
              props.renameSuccess();
            }            
          }}
          />
      </div>

      <div className={'card-footer'}>
        <Separator/>
      </div>

      <div className="marginTop">

        <div className={`${classNames.fullWidth}`}>
          <Label>{t('didyouencounteranyproblem')}</Label>
          <TextEditor
            value={props.journal && props.journal.encounteredProblem ? props.journal.encounteredProblem : ''}
            onChange={(value) => {
              if (value) {
                // props.formData.comments = value;
                props.setJournal('encounteredProblem', value);
              } else {
                props.setJournal('encounteredProblem', null);
              }
            }}
          />
        </div>

      </div>

      <div className="marginTop">
        <CustomFileUploader
          pmolId={props?.pmolId}
          uploadedImages={getImages(props.journal ? props.journal.pictureList : [], '2')}
          label={t('pictures')}
          uploadUrl={'PmolJournal/UploadJournalDocuments'}
          onSaveSuccess={(response: any) => props.setJournalImage('2', response)}
          disable={props.readOnly}
          productId={props.productId} checkedItems={[]}
          showCheckbox={true}
          showDelete={true}
          showCopyToPbs={true}
          showRename={true}
          sectionHeading={'JOURNALWORK'}
          renameSuccess={() =>{
            if(props.renameSuccess){
              props.renameSuccess();
            }            
          }}
          />
      </div>

      <div className={'card-footer'}>
        <Separator/>
      </div>

      <div className="marginTop">

        <div className={`${classNames.fullWidth}`}>
          <Label>{t('whatdidwelearntoday')}</Label>
          <TextEditor
            value={props.journal && props.journal.lessonsLearned ? props.journal.lessonsLearned : ''}
            onChange={(value) => {
              if (value) {
                // props.formData.comments = value;
                props.setJournal('lessonsLearned', value);
              } else {
                props.setJournal('lessonsLearned', null);
              }
            }}
          />
        </div>

      </div>

      <div className="marginTop">
        <CustomFileUploader
          pmolId={props?.pmolId}
          uploadedImages={getImages(props.journal ? props.journal.pictureList : [], '3')}
          label={t('pictures')}
          uploadUrl={'PmolJournal/UploadJournalDocuments'}
          onSaveSuccess={(response: any) => props.setJournalImage('3', response)}
          disable={props.readOnly}
          productId={props.productId} checkedItems={[]}
          showCheckbox={true}
          showDelete={true}
          showCopyToPbs={true}
          showRename={true}
          sectionHeading={'JOURNALWORK'}
          renameSuccess={() =>{
            if(props.renameSuccess){
              props.renameSuccess();
            }            
          }}
          />
      </div>

      <div className={'card-footer'}>
        <Separator/>
      </div>

      <div className="marginTop">

        <div className={`${classNames.fullWidth}`}>
          <Label>{t('doyouhaveotherthingstoreport')}</Label>
          <TextEditor
            value={props.journal && props.journal.reportedThings ? props.journal.reportedThings : ''}
            onChange={(value) => {
              if (value) {
                // props.formData.comments = value;
                props.setJournal('reportedThings', value);
              } else {
                props.setJournal('reportedThings', null);
              }
            }}
          />
        </div>

      </div>

      <div className="marginTop">
        <CustomFileUploader
          pmolId={props?.pmolId}
          uploadedImages={getImages(props.journal ? props.journal.pictureList : [], '4')}
          label={t('pictures')}
          uploadUrl={'PmolJournal/UploadJournalDocuments'}
          onSaveSuccess={(response: any) => props.setJournalImage('4', response)}
          disable={props.readOnly}
          productId={props.productId} checkedItems={[]}
          showCheckbox={true}
          showDelete={true}
          showCopyToPbs={true}
          showRename={true}
          sectionHeading={'JOURNALWORK'}
          renameSuccess={() =>{
            if(props.renameSuccess){
              props.renameSuccess();
            }            
          }}
          />
      </div>

        {/*PMOL JOB NOT DONE - WHAT'S LEFT TO DO?*/}
      {/*<div className="marginTop">*/}

      {/*<div className={`${classNames.fullWidth}`}>*/}
      {/*  <Label>{t('whatsLeftToDo?')}</Label>*/}
      {/*  <TextEditor*/}
      {/*    value={props.journal && props.journal.whatsLeftToDo ? props.journal.whatsLeftToDo : ''}*/}
      {/*    onChange={(value) => {*/}
      {/*      if (value) {*/}
      {/*        props.setJournal('whatsLeftToDo', value);*/}
      {/*      } else {*/}
      {/*        props.setJournal('whatsLeftToDo', null);*/}
      {/*      }*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</div>*/}

      {/*</div>*/}

      <div className={'card-footer'}>
        <Separator/>
        <Stack styles={stackStyles}>
          <Stack.Item align="end" styles={stackFooterBarItemStyles}>
            <ActionButton
              className={classNames.actionButton}
              iconProps={saveIcon}
              allowDisabledFocus
              disabled={props.readOnly}
              onClick={() => {
                props.PMOLSaveJournal();
              }}
            >
              <Label className={classNames.actionButton}>{t('save')} </Label>
            </ActionButton>
          </Stack.Item>
        </Stack>
      </div>
    </div>
  );
};

export default ProjectMoleculerJournal;

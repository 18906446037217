import { ActionButtonState } from '../../../../types/corporateProductCatalogue';
import {
  ActionButton,
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  DetailsRowCheck,
  DirectionalHint,
  getTheme,
  IColumn,
  IDetailsFooterProps,
  IDetailsRowBaseProps,
  IDetailsRowCheckStyles,
  IIconProps,
  IObjectWithKey,
  ISelection,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  SelectionMode,
  Stack,
  Sticky,
  StickyPositionType,
  TooltipHost,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../../../theme';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatwithoutTime, getBreadcrumbItemFromData } from '../../../../shared/util';

const theme = getTheme();

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const OrganisationGrid = (props: {
  editOrganisation: () => void;
  openModel: () => void;
  organisationSelection: ISelection<IObjectWithKey> | undefined;
  organisationSelectionDetails: {};
  organisationActionButtonState: ActionButtonState;
  title: string;
  deleteDataGrid: () => void;
  readOnly: boolean;
  listItem: any;
  taxonomyList: any[];
  isCertificate: boolean;
  selectedCertificationIdFromListPane?: any
}) => {
  const removeIcon: IIconProps = {
    iconName: 'Delete',
    styles: iconButtonStyles,
  };
  const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };
  const editIcon: IIconProps = { iconName: 'Edit', styles: iconButtonStyles };
  const { t } = useTranslation();
  const classNames = mergeStyleSets({
    wrapper: {
      // height: '100vh',
      position: 'relative',
      maxHeight: 'inherit',
    },
    pane: { maxWidth: 400 },
    textContent: { padding: '15px 10px' },
    actionIcon: {
      padding: 5,
      cursor: 'pointer',
    },
    labelRightWrapper: {
      textAlign: 'end',
      selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
    },
    actionButton: {
      color: uPrinceTheme.palette.themePrimary,
      cursor: 'pointer',
    },
    labelRightIcon: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: 2,
      paddingRight: 5,
    },
  });

  const competenciesColumns = [
    {
      key: 'column1',
      name: t('type'),
      fieldName: 'type',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('competencyTitle'),
      fieldName: 'competencyTitle',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('experienceLevel'),
      fieldName: 'experienceLevel',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  const certificationColumns = [
    {
      key: 'column1',
      name: t('type'),
      fieldName: 'type',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column2',
      name: t('certificationTitle'),
      fieldName: 'certificationTitle',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column5',
      name: t('Project'),
      fieldName: 'projectDefinitionName',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isMultiline: false,
    },
    {
      key: 'column4',
      name: t('product'),
      fieldName: 'pbsProductTitle',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isMultiline: false,
    },
    {
      key: 'column3',
      name: t('Country'),
      fieldName: 'countryName',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column6',
      name: t('startDate'),
      fieldName: 'startDate',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column7',
      name: t('endDate'),
      fieldName: 'endDate',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  const stackStyles: IStackStyles = { root: { padding: 0 } };
  const stackItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 40,
      alignItems: 'flex-start',
    },
  };

  const stackEditbarItemStyles: IStackItemStyles = {
    root: {
      display: 'flex',
      height: 45,
      alignItems: 'flex-end',
    },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 15,
    padding: 10,
  };

  const breadcrumbStyles = {
    root: {
      fontSize: 14,
      marginTop: -6,
    },
    item: { fontSize: 14 },
    list: { fontSize: 14 },
    listItem: { fontSize: 14 },
    chevron: { fontSize: 14 },
    overflow: { fontSize: 14 },
    overflowButton: { fontSize: 14 },
    itemLink: { fontSize: 14 },
  };
  const _onRenderDetailsHeader = (
    detailsFooterProps: IDetailsFooterProps,
  ): JSX.Element => {
    return (
      <DetailsRow
        {...detailsFooterProps}
        columns={detailsFooterProps.columns}
        item={{}}
        itemIndex={-1}
        groupNestingDepth={detailsFooterProps.groupNestingDepth}
        selectionMode={SelectionMode.single}
        selection={detailsFooterProps.selection}
        onRenderItemColumn={_renderDetailsHeaderItemColumn}
        onRenderCheck={_onRenderCheckForHeaderRow}
      />
    );
  };

  const _renderDetailsHeaderItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] =
    (item, index, column) => {
      if (column && column.fieldName && column.fieldName === 'type') {
        return (
          <div style={{ marginTop: 20 }}>
            <b>{t('type')}</b>
          </div>
        );
      } else if (
        column &&
        column.fieldName &&
        column.fieldName === 'competencyTitle'
      ) {
        return (
          <div style={{ width: 400, paddingLeft: 2 }}>
            &nbsp;
            <Label>{t('competencyTitle')}</Label>
          </div>
        );
        return null;
      } else if (
        column &&
        column.fieldName &&
        column.fieldName === 'experienceLevel'
      ) {
        return (
          <div style={{ width: 100, paddingLeft: 2 }}>
            &nbsp;
            <Label>{t('experienceLevel')}</Label>
          </div>
        );
        return null;
      } else if (
        props.isCertificate &&
        column &&
        column.fieldName &&
        column.fieldName === 'certificationTitle'
      ) {
        return (
          <div style={{ width: 400, paddingLeft: 2 }}>
            &nbsp;
            <Label>{t('certificationTitle')}</Label>
          </div>
        );
        return null;
      } else if (
        column &&
        column.fieldName &&
        column.fieldName === 'startDate'
      ) {
        return (
          <div style={{ width: 100, paddingLeft: 2 }}>
            {t('validityPeriod')}
            <Label>{t('startDate')}</Label>
          </div>
        );
        return null;
      } else if (
        props.isCertificate &&
        column &&
        column.fieldName &&
        column.fieldName === 'endDate'
      ) {
        return (
          <div style={{ width: 400, paddingLeft: 2 }}>
            &nbsp;
            <Label>{t('endDate')}</Label>
          </div>
        );
        return null;
      } else if (
        column &&
        column.fieldName &&
        column.fieldName === 'projectDefinitionName'
      ) {
        return (
          <div style={{ width: 100, paddingLeft: 2 }}>
            &nbsp;
            <Label>{t('Project')}</Label>
          </div>
        );
        return null;
      }
      else if (
        column &&
        column.fieldName &&
        column.fieldName === 'countryName'
      ) {
        return (
          <div style={{ width: 100, paddingLeft: 2 }}>
            &nbsp;
            <Label>{t('Country')}</Label>
          </div>
        );
        return null;
      }
      else if (
        column &&
        column.fieldName &&
        column.fieldName === 'pbsProductTitle'
      ) {
        return (
          <div style={{ width: 100, paddingLeft: 2 }}>
            &nbsp;
            <Label>{t('Product')}</Label>
          </div>
        );
        return null;
      }
      else {
        return null;
      }
    };

  const detailsRowCheckStyles: Partial<IDetailsRowCheckStyles> = {
    root: { visibility: 'hidden' },
  };

  const _onRenderCheckForHeaderRow: IDetailsRowBaseProps['onRenderCheck'] = (
    props,
  ): JSX.Element => {
    return (
      <DetailsRowCheck
        {...props}
        styles={detailsRowCheckStyles}
        selected={true}
      />
    );
  };

  let i = 0;
  const onRenderItemColumn = (
    item: any,
    index?: number,
    column?: IColumn,
    taxonomyList?: any,
  ) => {
    if (column && column.fieldName && column.fieldName === 'type') {
      if (!props.isCertificate) {
        var names = getBreadcrumbItemFromData(taxonomyList, item.competenciesTaxonomyId).map(function(item: any) {
          return item['text'];
        });
        let content = names.join('>');
        return <TooltipHost
          content={content}
          // This is the important part!
          id={item[column.fieldName]}
          calloutProps={{ gapSpace: 0 }}
          directionalHint={DirectionalHint.leftCenter}
        ><span>{content}</span></TooltipHost>;
        // return (
        //   <span>
        //     <div style={{ height: 20 }}>
        //       {/* <Breadcrumb
        //               items={ getBreadcrumbItemFromData(taxonomyList, item.competenciesTaxonomyId) }
        //               ariaLabel="With last item rendered as heading"
        //               overflowAriaLabel="More links"
        //               styles={breadcrumbStyles}
        //           /> */}
        //     </div>
        //   </span>
        // );
      } else {
        var names = getBreadcrumbItemFromData(taxonomyList, item.certificationTaxonomyId).map(function(item: any) {
          return item['text'];
        });
        let content = names.join('>');
        return <TooltipHost
          content={content}
          // This is the important part!
          id={item[column.fieldName]}
          calloutProps={{ gapSpace: 0 }}
          directionalHint={DirectionalHint.leftCenter}
        ><span>{content}</span></TooltipHost>;
        // return (
        //   <span>
        //     <div style={{ height: 20 }}>
        //       {/* <Breadcrumb
        //               items={ getBreadcrumbItemFromData(taxonomyList, item.certificationTaxonomyId) }
        //               ariaLabel="With last item rendered as heading"
        //               overflowAriaLabel="More links"
        //               styles={breadcrumbStyles}
        //           /> */}
        //     </div>
        //   </span>
        // );
      }
    } else if (
      column &&
      column.fieldName &&
      column.fieldName === 'competencyTitle'
    ) {
      return (
        <div>
          <span>{item.title}</span>
        </div>
      );
    } else if (
      column &&
      column.fieldName &&
      column.fieldName === 'experienceLevel'
    ) {
      return (
        <div>
          <span>{item.experienceLevelName}</span>
        </div>
      );
    } else if (
      column &&
      column.fieldName &&
      column.fieldName === 'certificationTitle'
    ) {
      return (
        <div>
          <span>{item.title}</span>
        </div>
      );
    } else if (
      column &&
      column.fieldName &&
      (column.fieldName === 'startDate' || column.fieldName === 'endDate')
    ) {
      const fieldContent = item[column.fieldName];
      return (
        <span>{formatwithoutTime(fieldContent)}</span>
      );
    } else if (
      column &&
      column.fieldName &&
      column.fieldName === 'countryName'
    ) {
      return (
        <div>
          <span>{item.countryName}</span>
        </div>
      );
    } else if (
      column &&
      column.fieldName &&
      column.fieldName === 'projectDefinitionName'
    ) {
      return (
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          <span>{item.projectDefinitionName}</span>
        </div>
      );
    } 
    else if (
      column &&
      column.fieldName &&
      column.fieldName === 'pbsProductTitle'
    ) {
      return (
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          <span>{item.pbsProductTitle ? item.pbsProductTitle : null}</span>
        </div>
      );
    } 

    return <span style={{ paddingLeft: 9 }}>-</span>;
  };

  return (
    <div style={{ padding: 12 }}>
      <div className="ms-Grid-row">
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={stackStyles}
          tokens={stackTokens}
        >
          <Stack.Item styles={stackItemStyles}>
            <Label style={{ marginTop: 10 }}>{props.title}</Label>
          </Stack.Item>
          {!props.readOnly && (
            <Stack.Item styles={stackEditbarItemStyles}>
              <ActionButton
                className={classNames.actionButton}
                disabled={props.organisationActionButtonState.add}
                iconProps={addIcon}
                allowDisabledFocus
                onClick={() => {
                  props.openModel();
                }}
              >
                <Label className={classNames.actionButton}>{t('add')} </Label>
              </ActionButton>
              <ActionButton
                iconProps={editIcon}
                disabled={props.organisationActionButtonState.edit}
                allowDisabledFocus
                onClick={() => {
                  props.openModel();
                }}
              >
                <Label className={classNames.actionButton}> {t('edit')}</Label>
              </ActionButton>
              <ActionButton
                iconProps={removeIcon}
                disabled={props.organisationActionButtonState.remove}
                allowDisabledFocus
                onClick={() => props.deleteDataGrid()}
              >
                <Label className={classNames.actionButton}>
                  {' '}
                  {t('remove')}
                </Label>
              </ActionButton>
            </Stack.Item>
          )}
        </Stack>
      </div>

      <div className="ms-Grid-row marginTop">
        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
          <div className={classNames.wrapper}>
            {/* <ScrollablePane>*/}
            <DetailsList
              items={props.listItem}
              columns={
                props.isCertificate ? certificationColumns : competenciesColumns
              }
              setKey="set"
              selectionMode={
                props.readOnly ? SelectionMode.none : SelectionMode.multiple
              }
              layoutMode={DetailsListLayoutMode.justified}
              selectionPreservedOnEmptyClick={true}
              checkboxVisibility={1}
              selection={props.organisationSelection}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
              onRenderItemColumn={(
                item: any,
                index?: number,
                column?: IColumn,
              ) => onRenderItemColumn(item, index, column, props.taxonomyList)}
              onRenderDetailsHeader={(headerProps, defaultRender) => {
                if (defaultRender !== undefined) {
                  return (
                    <Sticky
                      stickyPosition={StickyPositionType.Header}
                      isScrollSynced={true}
                      stickyBackgroundColor="transparent"
                    >
                      <div>{defaultRender(headerProps)}</div>
                    </Sticky>
                  );
                } else {
                  return _onRenderDetailsHeader(headerProps!!);
                }
                return <span></span>;
              }}
            />
            {/* </ScrollablePane>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganisationGrid;
